import React, { useState } from 'react';
import { useFormik } from "formik";
import { Alert, Card, CardBody, CardTitle, Col, Input, Label, Row, Table } from 'reactstrap';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { getaddFormData } from 'store/actions';
import { useDispatch } from 'react-redux';
import toastr from "toastr";
import Task_Detail_Form from './Task_Detail_form';
const New_Task_Form = ({details}) => {
  const user = useSelector((state) => state.Login.user);
  const sid = user.sessionID;
  const dispatch = useDispatch();
  const bus_Nameplate = details?.RouteDetail?.Plate;
  const cameraList = details?.RouteDetail?.Cam_Details;
  const issue_Type_List = useSelector(state => state.AddCam_Detail?.data?.Issue_type);
  const [isAddTask, setIsAddTask] = useState(false);

  // State to hold selected device MAC ID
  const [selectedDevice, setSelectedDevice] = useState('');

  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  toastr.options = {
    positionClass: "toast-top-center",
    progressBar: "progressBar",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "slideDown",
    hideMethod: "slideUp",
    timeOut: 1000,
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      FromDate: "" || '',
      Remarks: "" || '',
      Bus_Nameplate: bus_Nameplate || '',
      Issue_type: "" || '',
    },
    validationSchema: Yup.object({
      FromDate: Yup.string().required("Please Select Date"),
      Remarks: Yup.string().required("Please Enter Details"),
      Bus_Nameplate: Yup.string().required("Please Enter Bus Nameplate"),
      Issue_type: Yup.string().required("Please Select Issue Type"),
    }),
    onSubmit: (values) => {
      let queryParam = {
        sid: sid,
        id: 5,
        p1: values.FromDate,
        p2: 1,
        p3: selectedDevice,
        p4: values.Issue_type,
        p5: values.Remarks,
      };

      dispatch(getaddFormData(queryParam, (response) => {
        if (response?.OK) {
          toastr.success(response?.OK?.msg);
          setIsAddTask(true);
        }
        if (response?.error) {
          toastr.error(response?.error.msg);
        }
      }));
    }
  });

  // Function to handle device selection
  const handleDeviceSelection = (event) => {
    setSelectedDevice(event.target.value);
  };

  return (
    <Card>
      {
      !isAddTask ?
        <form onSubmit={validation.handleSubmit}>
          <Row className='p-3'>
            <Col className="col-12">
              <Row>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label className="form-label">Bus Nameplate</Label>
                    <Input
                      name="Camera_ID"
                      className="form-control"
                      placeholder="Enter Bus Nameplate"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.Bus_Nameplate || ""}
                      invalid={
                        validation.touched.Bus_Nameplate && validation.errors.Bus_Nameplate ? true : false
                      } disabled
                      autoComplete="off" />
                    {validation.touched.Bus_Nameplate && validation.errors.Bus_Nameplate ? (
                      <Alert color="danger">{validation.errors.Bus_Nameplate}</Alert>
                    ) : null}
                  </div>
                </Col>
                <Row>
                  <Col className='col-6'>
                    <div className="mb-3">
                      <Label className="form-label">Issue Type</Label>
                      <select className="form-select" name="Issue_type"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Issue_type || ""}>
                        <option value="" disabled hidden>Select Issue Type</option>
                        {issue_Type_List && issue_Type_List.map((item, index) => (
                          <option value={item} key={index}>{item}</option>
                        ))}
                      </select>
                      <div>
                        {validation.touched.Issue_type && validation.errors.Issue_type ? (
                          <Alert color="danger">{validation.errors.Issue_type}</Alert>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="mb-3">
                      <Label className="form-label">Start Date </Label>
                      <Input
                        name="FromDate"
                        className="form-control"
                        placeholder="Select Date  "
                        type="date"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        min={getCurrentDateTime()}
                        value={validation.values.FromDate || ""}
                        invalid={
                          validation.touched.FromDate && validation.errors.FromDate ? true : false
                        } />
                      {validation.touched.FromDate && validation.errors.FromDate ? (
                        <Alert color="danger">{validation.errors.FromDate}</Alert>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Card>
                  <Label className="form-label">Choose Device</Label>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr key="thead">
                          <th>Select </th>
                          <th> Device</th>
                          <th>Position</th>
                          <th>IP Address</th>
                          <th>MAC ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cameraList && cameraList.map(item => (
                          <tr key={item.cam}>
                            <th scope="row">
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="deviceRadios"
                                  id={`deviceRadios-${item.cam}`}
                                  value={item.mac}
                                  onChange={handleDeviceSelection} // Handle device selection
                                />
                              </div>
                            </th>
                            <td>Camera</td>
                            <td>{item.Cam}</td>
                            <td>{item.ip}</td>
                            <td>{item.mac}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Row>
              <Row>
                <div className="mb-3">
                  <Label className="form-label">Remarks</Label>
                  <Input
                    name="Remarks"
                    className="form-control"
                    placeholder="Enter Remarks"
                    type="textarea"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.Remarks || ""}
                    invalid={
                      validation.touched.Remarks && validation.errors.Remarks ? true : false
                    }
                    autoComplete="off" />
                  {validation.touched.Remarks && validation.errors.Remarks ? (
                    <Alert color="danger">{validation.errors.Remarks}</Alert>
                  ) : null}
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='text-center pb-3'>
              <button type="submit" className='btn btn-primary'>Submit</button>
            </Col>
          </Row>
        </form>
        :
        <Task_Detail_Form details={details} />
      }
    </Card>
  );
};

export default New_Task_Form;

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import images from 'assets/images/images.png';
import { useSelector } from "react-redux";
import { getdebug_screenData } from "store/actions";
import { useDispatch } from "react-redux";
import Holiday_Form from "pages/Detail-Dashboard/Holiday_Form";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [img_src, setImage] = useState(images);
  const login = useSelector(state => state.Login?.userData?.data);
  const username = useSelector(state => state.Login?.userData?.data?.name);
  const userImage = useSelector(state => state.Login?.userData?.data?.mypic);
  const [HolidayForm, setHolidayForm] = useState(false);
  const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
  const [role, setRole] = useState([]);
  useEffect(() => {
    if (permission !== null) {
      setRole(permission.split(",").map(Number));
    }
  }, [])

  console.log(username);
  console.log(userImage);
  useEffect(() => {
    setImage(userImage)
  }, [login]);
  const resetImage = (e) => {
    e.target.src = images;
  }
  const handleHolidayCalender = () => {
    setHolidayForm(true);
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={img_src} onError={(e) => { resetImage(e) }}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username.trim().length > 0 ? username : "Admin"}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/debug_screen" className="dropdown-item">
            <span>{props.t("Debug Screen")}</span>
          </Link>
          {role.includes(24) ?
            <Link className="dropdown-item" onClick={handleHolidayCalender}>
              <span>{props.t("Holiday Calender")}</span>
            </Link>
            : <></>
          }
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={HolidayForm} style={{ maxWidth: "730px" }} toggle={() => { setHolidayForm(!HolidayForm) }}>
        <ModalHeader toggle={() => { setHolidayForm(!HolidayForm) }}>Holiday Calender</ModalHeader>
        <Holiday_Form />
      </Modal>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);

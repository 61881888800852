import { error } from "toastr";
import {
    DATA_TABLE_API_FAIL,
    DATA_TABLE_API_SUCCESS, SET_CHANGING_DATA_RESPONSE, SET_SELECTED_FILES
} from "./actionTypes";

const INIT_STATE = {
    data: {},
};

const Data_Table = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DATA_TABLE_API_SUCCESS:
            return {
                ...state,
                data: action.payload.OK.data
            };

        case DATA_TABLE_API_FAIL:
            return {
                ...state,
                apiDataError: action.payload
            };
        case SET_SELECTED_FILES:
            return {
                ...state,
                files: action.payload
            };
        case SET_CHANGING_DATA_RESPONSE:
            return {
                ...state,
                message: action.payload
            };

        default:
            return state;
    }
}

export default Data_Table;
import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle, CardHeader } from "reactstrap";

import ApexRadial from "./AttendenceApexRadial";
import { useSelector } from "react-redux";

const Attandence = (Attendance) => {
  console.log(Attendance);
  const Total = Attendance?.Attendance?.Attendance?.Total;
  const OnTime = Attendance?.Attendance?.Attendance?.OnTime;
  const Late = Attendance?.Attendance?.Attendance?.LATE;
  const Absent = Attendance?.Attendance?.Attendance?.ABSENT;
  const Alert = Attendance?.Attendance?.Attendance?.ALERT;
  const Err_Count = Attendance?.Attendance?.Attendance?.Err_Count;
  const present = 100 - Absent;
  console.log(present);
  const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
  const [role, setRole] = useState([]);
  useEffect(() => {
    if (permission !== null) {
      setRole(permission.split(",").map(Number));
    }
  }, [])

  return (
    <React.Fragment>
      {" "}
      <Card className="summary_card">
        <CardHeader><h4>Attendance</h4></CardHeader>
        <CardBody >
          <Row>
            <Row>
              <div>
                <ApexRadial dataColors='["--bs-primary"]' percentPresent={present === undefined || isNaN(present) ? 0 : present} />
              </div>
            </Row>
            <Row className="pt-0" >
              <Col>
                <p>
                  <h5>{Total}</h5>
                  <span className="text-muted">Students</span>
                </p>
              </Col>
              <Col>
                <p>
                  <h5>{OnTime}%</h5>
                  <span className="text-muted">OnTime</span>
                </p>
              </Col>
              <Col>
                <p>
                  <h5>{Late}%</h5>
                  <span className="text-muted">Late</span>
                </p>
              </Col>
            </Row>
            <Row className="pt-0" >
              <Col>
                <p>
                  <h5>{Absent}%</h5>
                  <span className="text-muted">Absent</span>
                </p>
              </Col>
              <Col>
                <p>
                  <h5>{Alert}%</h5>
                  <span className="text-muted">Alert</span>
                </p>
              </Col>
              <Col>
                <p>
                  <h5>{Err_Count}%</h5>
                  <span className="text-muted">Error</span>
                </p>
              </Col>
            </Row>
            {permission !== null ?
              role.includes(34) ?
                <div className='text-end'>
                  <a href="#" className='link'>For More Details</a>
                </div>
                :
                <div></div>
              : <></>
            }
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Attandence;

import { detail_dashboard_api_2} from "helpers/backend_helper";
import {   ROUTE_LIST_API_FAIL, ROUTE_LIST_DATA } from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { routeListApiFail, routeListApiSuccess } from "./actions";
import { error } from "toastr";





function* getRouteListApiData({payload : queryParam,callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(detail_dashboard_api_2,queryParam);
        console.log("response"+response);
        yield put(routeListApiSuccess(response));
        // localStorage.setItem('route_list',response?.OK);
        callback && callback(response)
    } catch (error) {
        yield put(routeListApiFail(ROUTE_LIST_API_FAIL, error));
    }
}


function* routeListSaga() {
    yield takeEvery(ROUTE_LIST_DATA, getRouteListApiData);
}


export default routeListSaga;

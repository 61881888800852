import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { Alert, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row, Spinner, Table } from 'reactstrap';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { getaddFormData } from 'store/actions';
import toastr from "toastr";
import New_Task_Form from './New_Task_form';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import index from 'pages/Dashboard-Blog';
import { isEmpty } from 'lodash';

const Task_Detail_Form = ({details}) => {
    const user = useSelector(state => state.Login.user);
    const sid = user.sessionID;
    const dispatch = useDispatch();
    const task_Type_List = useSelector(state => state.AddCam_Detail?.data?.Tickets);
    const task_Detail_List = useSelector(state => state.AddForm_Detail?.data?.data);
    const macAddress = useSelector(state => state.Active_Store?.macAddress);
    const dates = useSelector(state => state.Active_Store?.dates);
    const issue_Type_List = useSelector(state => state.AddCam_Detail?.data?.Issue_type);
    const [selectedDates, setSelectedDates] = useState([]);
    const [fromDate, setFromDate] = useState(dates.startDate);
    const [taskDate, setTaskDate] = useState("");
    const [toDate, setTODate] = useState(dates.endDate);
    const [camera, setCamera] = useState("");
    const [issue_type, setIssue_Type] = useState("");
    const [edit, setEdit] = useState(false);
    const [newTaskForm, setNewTaskForm] = useState(false);
    const [editedRow, setEditedRow] = useState(null);
    const [formData, setFormData] = useState([]);
    const [newTaskbtn, setNewTaskbtn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setFromDate(moment(selectedDates[0]).format("YYYY-MM-DD"));
            setTODate(moment(selectedDates[1]).format("YYYY-MM-DD"));
        }

        setSelectedDates(selectedDates);
    };

    useEffect(() => {
        if (task_Detail_List) {
            setFormData(task_Detail_List);
        }
    }, [task_Detail_List]);

    const handleEdit = (e, index) => {
        e.preventDefault();
        setEditedRow(index);
        // setEdit(false);
    };
    toastr.options = {
        positionClass: "toast-top-center",
        progressBar: "progressBar",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: 2000,
    };

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            Task_Type: "" || '',
            Remarks: "" || '',
            Status: "" || '',
        },
        validationSchema: Yup.object({
            Remarks: Yup.string().required("Please Enter Remarks"),
            Status: Yup.string().required("Please Select Status"),
        }),
        onSubmit: (values, { setFieldValue }) => {
            let queryParam = {
                sid: sid,
                id: 5,
                p1: moment(taskDate).format("YYYY-MM-DD"),
                p2: values.Status,
                p3: camera ? camera : macAddress,
                p4: issue_type,
                p5: values.Remarks,
            };
            dispatch(getaddFormData(queryParam, (response) => {
                if (response?.OK) {
                    setFieldValue('Remarks', '');
                    setEditedRow(null);
                    let queryParam = {
                        sid: sid,
                        id: 6,
                        p1: fromDate,
                        p2: validation.values.Task_Type ? validation.values.Task_Type : 0,
                        p3: macAddress,
                        p4: toDate,
                        p5: 0,
                    };
                    dispatch(getaddFormData(queryParam, (response) => {
                        if (response?.error) {
                            setFormData([]);
                            toastr.error(response?.error?.msg)
                        }
                    }));
                    toastr.success(response?.OK?.msg);
                }
                if (response?.error) {
                    toastr.error(response?.error.msg);
                }
            }));
        },
    });

    const handleNewTask = () => {
        setNewTaskForm(true);
    }
    useEffect(() => {
        setEdit(!edit);
        if (formData.length > 0) {
            formData.map((item, index) => {
                if (index === editedRow) {
                    setTaskDate(item.date),
                        setCamera(item.camera),
                        setIssue_Type(item.ticket);
                }
            })
        }
    }, [editedRow])

    useEffect(() => {
        if (validation.values.Task_Type === "1" || validation.values.Task_Type === "2") {
            setNewTaskbtn(true);
        }
        else {
            setNewTaskbtn(false);
        }
        if (selectedDates.length !== 1) {
            let queryParam = {
                sid: sid,
                id: 6,
                p1: fromDate,
                p2: validation.values.Task_Type ? validation.values.Task_Type : 0,
                p3: macAddress,
                p4: toDate,
                p5: 0,
            };
            dispatch(getaddFormData(queryParam, (response) => {
                if (response?.OK) {
                    setFormData(response?.OK.data.data);
                    setIsLoading(false)
                }
                if (response?.error) {
                    setFormData([]);
                    toastr.error(response?.error?.msg)
                    setIsLoading(false)
                }
            }));
        }
    }, [selectedDates, validation.values.Task_Type]);
    return (
        <Card>
            {
                !isLoading ?
                    (!newTaskForm ? (
                        <Form onSubmit={validation.handleSubmit}>
                            <Row >
                                <Row className='p-3'>
                                    <Col className="col-6">
                                        <div className="mb-3">
                                            <Label className="form-label">Task Type</Label>
                                            <select
                                                className="form-select"
                                                name="Task_Type"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.Task_Type || ""}
                                            >
                                                {/* <option value="" disabled hidden>Select Task Type</option> */}
                                                {task_Type_List && task_Type_List.map((item, index) => (
                                                    <option value={index} key={index}>{item}</option>
                                                ))}
                                            </select>
                                            <div>
                                                {validation.touched.Task_Type && validation.errors.Task_Type ? (
                                                    <Alert color="danger">{validation.errors.Task_Type}</Alert>
                                                ) : null}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='col-6'>
                                        <div className="mb-3">
                                            <Label className="form-label">Select Date Range</Label><br />
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="YYYY-MM-DD"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "Y-m-d"
                                                }}
                                                value={selectedDates}
                                                onChange={handleDateChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-12'>
                                        <Card>
                                            <CardBody className='pe-0'>
                                                <div className="table-responsive" >
                                                    <Table className="table table-bordered m-0 p-0">
                                                        <thead className="table-light text-center" style={{ maxWidth: "700px" }}>
                                                            <tr key="thead">
                                                                <th>Date/Time </th>
                                                                <th>User</th>
                                                                <th>Assignee</th>
                                                                <th>Status</th>
                                                                <th>Device ID/ Group</th>
                                                                <th>Issue Type</th>
                                                                <th>Remarks</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {formData.length > 0 ?
                                                                formData.map((item, index) => (
                                                                    <tr key={index} className='text-center'>
                                                                        <td className='p-0 pt-3' style={{ textWrap: "nowrap", whiteSpace: "break-spaces" }}> {moment(item.date).format("DD-MM-YYYY HH:mm:ss")}</td>
                                                                        <td className='p-1 pt-3'>{item.user}</td>
                                                                        <td className='p-0 pt-3'>{isEmpty(item.assignee) ? "Not assigned" : item.assignee}</td>
                                                                        <td className='p-0 pt-3'>
                                                                            {index === editedRow ? (
                                                                                <div>
                                                                                    <select className="form-select pt-0" name="Status"
                                                                                        onChange={validation.handleChange}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={validation.values.Status || ""}
                                                                                    // style={{ border: 'none' }}
                                                                                    >
                                                                                        <option value="" disabled hidden>Select Status</option>
                                                                                        <option value="0" >Closed</option>
                                                                                        <option value="1" >Open</option>
                                                                                        <option value="2" >Update</option>
                                                                                    </select>
                                                                                    {validation.touched.Status && validation.errors.Status ? (
                                                                                        <Alert color="danger">{validation.errors.Status}</Alert>
                                                                                    ) : null}
                                                                                </div>
                                                                            ) : (
                                                                                item.Status === "0" ? "Closed" : item.Status === "1" ? "Open" : item.Status === "2" ? "Update" : item.Status
                                                                            )}
                                                                        </td>
                                                                        <td className='p-0 pt-3'>{item.camera}</td>
                                                                        <td className='p-0 pt-3'>{item.ticket}</td>
                                                                        <td className='p-0 pt-3'>
                                                                            {
                                                                                index === editedRow ? (
                                                                                    <div>
                                                                                        <Input
                                                                                            name="Remarks"
                                                                                            className="form-control pt-0"
                                                                                            placeholder="Enter Remarks"
                                                                                            type="text"
                                                                                            onChange={validation.handleChange}
                                                                                            onBlur={validation.handleBlur}
                                                                                            value={validation.values.Remarks || ""}
                                                                                            invalid={
                                                                                                validation.touched.Remarks && validation.errors.Remarks ? true : false
                                                                                            }
                                                                                            autoComplete="off" />
                                                                                        {validation.touched.Remarks && validation.errors.Remarks ? (
                                                                                            <Alert color="danger">{validation.errors.Remarks}</Alert>
                                                                                        ) : null}
                                                                                    </div>
                                                                                ) :
                                                                                    item.remarks.startsWith('http') ? (
                                                                                        <a
                                                                                            className="btn btn-link font-size-16"
                                                                                            role="button"
                                                                                            target='_blank'
                                                                                            rel="noreferrer"
                                                                                            href={item.remarks}
                                                                                        >
                                                                                            &nbsp;<i className="bx bxs-folder-open "></i>
                                                                                        </a>
                                                                                    ) : (
                                                                                        item.remarks
                                                                                    )
                                                                            }
                                                                        </td>
                                                                        <td className='text-center p-1'>
                                                                            {
                                                                                index !== editedRow ? (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-primary btn-sm"
                                                                                        onClick={(e) => handleEdit(e, index)}
                                                                                    >
                                                                                        Edit
                                                                                    </button>

                                                                                ) : (
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="btn btn-primary btn-sm"
                                                                                    >
                                                                                        Save
                                                                                    </button>
                                                                                )}
                                                                        </td>
                                                                    </tr>
                                                                )) : <></>}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='pt-3'>
                                    <Col className='text-center pb-3'>
                                        {
                                            !newTaskbtn ?
                                                <button type="button" className='btn btn-primary' onClick={(e) => { e.preventDefault(); handleNewTask(); }}>New Task</button>
                                                : null
                                        }
                                    </Col>
                                </Row>
                            </Row>
                        </Form>
                    ) : (
                        <New_Task_Form details={details} />
                    ))

                    :
                    (<div>
                        <Spinner color="primary" className="position-absolute start-50" />
                    </div>)

            }
        </Card>
    );
};

export default Task_Detail_Form;

import {
    SUB_GROUP_API_SUCCESS,
    SUB_GROUP_API_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    data: {},
    //  activeRoute: "",
};

const SubGroups_Detail = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUB_GROUP_API_SUCCESS:
            return {
                ...state,
                data: action.payload.OK.data
            };

        case SUB_GROUP_API_FAIL:
            return {
                ...state,
                apiDataError: action.payload.error
            };

        default:
            return state;
    }
}

export default SubGroups_Detail;
import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//jobs
import JobReducer from "./jobs/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Dasboard crypto
import DashboardCrypto from "./dashboard-crypto/reducer";

//Dasboard blog
import DashboardBlog from "./dashboard-blog/reducer";

//Dasboard job
import DashboardJob from "./dashboard-jobs/reducer";

//Summary Dashboard
import Summary_Dashboard from "./summary-dashboard/reducer"

import Detail_Dashboard from "./detail-dashboard/reducer"

import Route_List from "./route-list/reducer";
import Active_Store from "./date-store/reducer";
import Data_Table from "./table-data/reducer";
import Live_Camera_Data from "./fetch-live-data/reducer";
import SubGroups_Detail from "./subgroup-list/reducer"
import { LOGOUT_USER_SUCCESS } from "./auth/login/actionTypes";
import SnapShot_Detail from "./snapshot/reducer";
import AddCam_Detail from "./add-cam/reducer";
import AddForm_Detail from "./add-form/reducer";
import Debug_Screen_Detail from "./debug-screen/reducer";
import SearchMac_ID from "./search-mac/reducer";
const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  JobReducer,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardCrypto,
  DashboardBlog,
  DashboardJob,
  Summary_Dashboard,
  Detail_Dashboard,
  Route_List,
  Active_Store,
  Data_Table,
  Live_Camera_Data,
  SubGroups_Detail,
  SnapShot_Detail,
  AddCam_Detail,
  AddForm_Detail,
  Debug_Screen_Detail,
  SearchMac_ID,
});


const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER_SUCCESS)
      state = undefined;

  return appReducer(state, action);
};

export default rootReducer;

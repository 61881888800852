import { summary_dashboard_data, summary_dashboard_line_chart } from "helpers/backend_helper";
import { GET_SUMMARY_CHART_DATA, GET_SUMMARY_DATA } from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { summaryApiFail, summaryApiSuccess } from "./actions";




function* getApiData({payload : queryParam}) {
    try {
        var response;
        // console.log("summary data queryparam :",queryParam);
        response = yield call(summary_dashboard_data,queryParam);
        if(response?.error)
        {
            yield put(summaryApiFail(GET_SUMMARY_DATA,response?.error));

        }
        if(response?.OK)
        {
            yield put(summaryApiSuccess(GET_SUMMARY_DATA,response));
        }
    } catch (error) {
        yield put(summaryApiFail(GET_SUMMARY_DATA, error));
    }
}

function* getSummaryChartsData({payload : queryParam}) {
    try {
        var response;
        response = yield call(summary_dashboard_line_chart,queryParam);
        if(response?.error)
        {
          yield put(summaryApiFail(GET_SUMMARY_CHART_DATA,response?.error));
        }
        if(response?.OK)
        {
            yield put(summaryApiSuccess(GET_SUMMARY_CHART_DATA, response.OK));
        }
    } catch (error) {
        let errormsg = [error?.response?.data?.error];
        yield put(summaryApiFail(GET_SUMMARY_CHART_DATA, errormsg));
    }
}

export function* watchGetApiData() {
    yield takeEvery(GET_SUMMARY_CHART_DATA, getSummaryChartsData);
    yield takeEvery(GET_SUMMARY_DATA, getApiData);
}

function* summaryDashboardSaga() {
    yield all([fork(watchGetApiData)]);
}

export default summaryDashboardSaga;

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoLightSvg from "../../assets/images/logo5-latest-smallest-1.jpg"
//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  setDates,
  getSummaryData,
  setSubGroup,
  getSummaryChartsData,
  setIsLoading,
  getLiveCameraData,
  getaddFormData,
  getaddCamData,
  getdebug_screenData,
} from "../../store/actions";

import classnames from "classnames";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useDispatch } from 'react-redux';
import { getDetailData } from "../../store/actions"
import { useSelector } from 'react-redux';
import { date } from 'yup';
// import { en } from '@fullcalendar/core/internal-common';
import moment from 'moment';
import Overall from 'pages/Detail-Dashboard/Overall';

const Header = props => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.Login.user);
  const sid = user.sessionID;

  const routeName = useSelector(state => state.Route_List.activeRoute);

  const [customActiveTab, setcustomActiveTab] = useState("month");

  const [selectedDates, setSelectedDates] = useState([]);
  const main_group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)

  const group = useSelector(state => state.Active_Store?.sub_group);

  const ref1 = useRef(null);

  const macAddress = useSelector(state => state.Active_Store?.macAddress);
  const activeCamera1 = useSelector(state => state.Active_Store?.activeCamera);

  useEffect(() => {

    if (ref1.current) {
      clearInterval(ref1.current)
    }
    ref1.current = setInterval(async () => {
      console.log("Interval Calls Live DL Status", ref1.current);
      let queryParams = {
        sid: sid,
        mac: macAddress ? macAddress : "",
      }
      dispatch(getLiveCameraData(queryParams));
    }, 30000);
    return () => {
      if (ref1.current) {
        clearInterval(ref1.current)
      }
    }
  }, [macAddress, activeCamera1]);


  useEffect(()=>{
    let queryParam = {
      sid: sid,
      id: '2',
    }
    dispatch(getaddCamData(queryParam));
  },[])

  const handleDateChange = (selectedDates) => {
    // Assuming selectedDates is an array with two Date objects
    if (selectedDates.length === 2) {

      // console.log(selectedDates[0]+"---"+selectedDates[1] );

      let startDate = moment(selectedDates[0]).format("YYYY-MM-DD");
      let endDate = moment(selectedDates[1]).format("YYYY-MM-DD");

      getDetailDashboardData(startDate, endDate);
      getSummaryDashboardData(startDate, endDate);
      setDateInStore(startDate, endDate);
      setcustomActiveTab('');
    }

    setSelectedDates(selectedDates);
  };

  const setDateInStore = (startDate, endDate) => {
    dispatch(setDates({ startDate, endDate }));
  }


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (document.documentElement.clientWidth < 992) {
      console.log(document.documentElement.clientWidth);
      body.classList.toggle("sidebar-enable");
      body.classList.remove("vertical-collpsed");
    } else {
      console.log(document.documentElement.clientWidth);
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

  }

  const toggleCustom = tab => {

    setcustomActiveTab(tab);
    let endDate;
    let startDate;
    if (tab === "today") {

      startDate = moment().subtract(1, 'days').format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }
    if (tab === "week") {
      startDate = moment().startOf('isoWeek').format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
    }
    if (tab === "month") {

      startDate = moment().startOf('month').format("YYYY-MM-DD");
      endDate = moment().add(1, "days").format("YYYY-MM-DD");

    }

    getDetailDashboardData(startDate, endDate);
    getSummaryDashboardData(startDate, endDate);
    setDateInStore(startDate, endDate);
    setSelectedDates();
  };


  const getDetailDashboardData = (startDate, endDate) => {
    let queryParams = {
      sid: sid,
      date: startDate,
      enddate: endDate,
      route: routeName,
      higroup: main_group,
    }
    // console.log(queryParams);
    dispatch(getDetailData(queryParams));
  }


  const getSummaryDashboardData = (startDate, endDate) => {
    let queryParams = {
      sid: sid,
      date: startDate,
      enddate: endDate,
      route: group,
    }
    // console.log(queryParams);
    dispatch(getSummaryData(queryParams));
    dispatch(setIsLoading(true));
    dispatch(getSummaryChartsData(queryParams));
  }

  useEffect(() => {
    dispatch(setSubGroup(main_group));
    let queryParams = {
      sid: sid,
      mac: macAddress ? macAddress : "",
    }
    dispatch(getLiveCameraData(queryParams));

  }, [dispatch]);

  const location = useLocation();
  const [overall, setOverAll] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const hideOverallComponent = windowWidth < 1400;

  useEffect(() => {
    console.log("locations", location);
    window.addEventListener('resize', handleResize);
    if (location.pathname === "/detail_dashboard") {
      setOverAll(true);
    } else {
      setOverAll(false);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [location, window]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header" style={{ height: " 77pt" }}>
          <div className="d-flex">
            <button type="button" onClick={() => { tToggle(); }} className="btn btn-sm px-3 font-size-16 header-item " id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <Col sm={overall && !hideOverallComponent ? 3 : 4} style={{ height: "45px", overflowY: "auto", overflowX: "hidden" }}>
            <Nav tabs className="nav-tabs-custom " >
              <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "today", })} onClick={() => { toggleCustom("today"); }}>
                  <span className="d-sm-block">Today</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "week", })} onClick={() => { toggleCustom("week"); }}>
                  <span className="d-sm-block">This Week</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "month", })} onClick={() => { toggleCustom("month"); }}>
                  <span className="d-sm-block">This Month</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>


          <Col sm={overall && !hideOverallComponent ? 3 : 6} style={{ overflowX: "hidden" }}>
            <span style={{ color: "--bs-emphasis-color", fontWeight: 500 }}>  Date Range : &nbsp;
              <Flatpickr
                className=""
                placeholder="YYYY-MM-DD"
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d"
                }}
                value={selectedDates}
                onChange={handleDateChange}
              />
            </span>
          </Col>
          {overall && !hideOverallComponent &&
            <Col sm={4}>
              <Overall />
            </Col>
          }
          <div className="d-flex">


            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />

          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));

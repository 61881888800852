import { add_cam_api} from "helpers/backend_helper";
import {   GET_ADD_CAM_DATA,ADD_CAM_API_FAIL } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import { addCamApiFail,addCamApiSuccess} from "./actions";


function* getAddCamApiData({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(add_cam_api,queryParam);
        console.log("response"+response);
        if(response.error)
        {
            console.log("errorrrr")
            yield put(addCamApiFail(ADD_CAM_API_FAIL, response.error));    
        }
        else{
            yield put(addCamApiSuccess(response));
        }
        callback && callback(response)
    } catch (error) {
        yield put(addCamApiFail(ADD_CAM_API_FAIL, error));
    }
}


function* addCamSaga() {
    yield takeEvery(GET_ADD_CAM_DATA, getAddCamApiData);
}


export default addCamSaga;

import React, { useEffect, useState } from 'react'
import { Alert, Card, CardBody, CardTitle, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import modelImage from '../../assets/images/users/avatar-7.jpg'
import { useDispatch } from 'react-redux';
import { getDetailData, getsnapshotData } from 'store/actions';
import { useSelector } from 'react-redux';
import images from 'assets/images/images.png';
import toastr from "toastr";
import Bus_Detail_Form from './Bus_Detail_form';
import Rtsp from './rtsp';
function RouteDetail({ details }) {


  const dispatch = useDispatch();
  const user = useSelector(state => state.Login.user)
  const sid = user.sessionID;
  const macAddress = useSelector(state => state.Active_Store?.macAddress);
  const files = useSelector(state => state.Data_Table.files);
  const dates = useSelector(state => state.Active_Store?.dates);
  const startDate = dates?.startDate;
  const endDate = dates?.endDate;
  const routeName = useSelector(state => state.Route_List?.activeRoute);
  const regex = new RegExp("[()]", "g");
  let route = routeName.replace(regex, "").trim().split(" ")[0];
  const group = useSelector(state => state.Active_Store?.sub_group)
  const [img_src, setImage] = useState(images);
  const activeCamera = useSelector(state => state.Active_Store?.activeCamera);
  const [modal, setModal] = useState(false);
  const [isLiveStreamOpen, setIsLiveStreamOpen] = useState(false);
  const liveCameras = useSelector(state => state.Live_Camera_Data?.data);
  const [liveStatus, setliveStatus] = useState(false);
  const Bus_Nameplate = details?.RouteDetail.Plate;
  const [rtsp, setRtsp] = useState();
  const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
  const [role, setRole] = useState([]);
  useEffect(() => {
    if (permission !== null) {
      setRole(permission.split(",").map(Number));
    }
  }, [])

  toastr.options = {
    positionClass: "toast-top-center",
    progressBar: "progressBar",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "slideDown",
    hideMethod: "slideUp",
    timeOut: 2000,
  };
  const takeSnapshot = () => {
    toastr.success("Request sent")
    if (macAddress) {
      let queryParam = {
        sid: sid,
        id: files?.length > 0 ? liveStatus ? 1 : 2 : 0,
        mac: macAddress,
        filename: files?.length > 0 ? files.toString() : "",
      }
      dispatch(getsnapshotData(queryParam, (response) => {
        if (response?.OK) {
          if (response.OK.data === null) {
            toastr.warning("Null Response")
          }
          else {
            toastr.success(response.OK.data)
          }
        }
        if (response?.error) {
          toastr.error(response.error.msg);
        }
      }));

      setTimeout(() => {
        console.log("5 sec ");
        let queryParams = {
          sid: sid,
          date: startDate,
          enddate: endDate,
          route: route,
          higroup: group,
        }
        dispatch(getDetailData(queryParams));
      }, 5000);
    }
  }
  const setColor = () => {
    if (details?.RouteDetail?.R_Status == 'A')
      return "#FFBF00"
    if (details?.RouteDetail?.R_Status == 'Y')
      return "#FFFF00"
    if (details?.RouteDetail?.R_Status == 'R')
      return "#FF0000"
    if (details?.RouteDetail?.R_Status == 'G')
      return "#00FF00"
  }

  const resetImage = (e) => {
    e.target.src = images;
  }
  useEffect(() => {
    details?.RouteDetail?.Cam_Details?.map((item) => {
      if (activeCamera === item?.Cam) {
        setImage(item?.buspic);
        let rtspurl = (item?.rtsp).split(":");
        setRtsp(rtspurl.slice(0, 3).join(":") + `:${item?.rtspport}` + ((item?.rtsp).split(":")).slice(3).join(":"));
      }
    })
  }, [activeCamera, details]);

  const openModel = () => {
    setModal(true);
  }
  const livestream = () => {
    console.log("livestream");
    setIsLiveStreamOpen(!isLiveStreamOpen);
    console.log(rtsp);
  };
  const [isshowDetailOpen, setIsShowDetailOpen] = useState(false);
  const showDetail = () => {
    setIsShowDetailOpen(!isshowDetailOpen);
  }
  useEffect(() => {
    if (liveCameras) {
      let liveRoute = liveCameras?.data?.filter(item => item.live.bus === activeCamera);
      if (liveRoute?.length > 0) {
        setliveStatus(true);
      } else {
        setliveStatus(false);
      }
    } else {
      setliveStatus(false);
    }
  }, [liveCameras, details, activeCamera])

  

  return (
    <React.Fragment>
      {" "}
      <Card className='cam_and_route'>
        <CardBody className='pt-1 pb-1'>
          <CardTitle className="mb-3">
            <Row>
              <Col className='align-self-center' style={{ textAlign: 'right' }} >
                <h3>Bus/Route Details {details?.RouteDetail?.Route}</h3>
              </Col>
            </Row>
          </CardTitle>

          <Row>
            <Col sm="5" className=''>
              <Row><img src={img_src} alt="" className="rounded-circle routeDetail-image " style={{ cursor: "pointer" }} onError={(e) => { resetImage(e) }} onClick={openModel} /></Row>
              <Row>
                <Col>
                  <div className="avatar-sm align-self-center mini-stat-icon ">
                    <span className='avatar-title rounded-circle bg-secondary' onClick={role.includes(29) ? takeSnapshot : null} style={{ cursor: role.includes(29) ? 'pointer' : 'default' }}>
                      <i className="mdi mdi-camera font-size-24 " />
                    </span>
                  </div>
                </Col>
                <Col className=' py-3 px-2'>
                  <div className="avatar-sm align-self-center mini-stat-icon " style={{ width: "65px", height: "30px", backgroundColor: setColor(), borderRadius: "60px" }}  ></div>
                </Col>
                <Col>
                  <div className="avatar-sm  align-self-center mini-stat-icon ">
                    <span className='avatar-title rounded-circle  bg-secondary' onClick={role.includes(28) && liveStatus ? livestream : null} style={{ cursor: role.includes(28) && liveStatus ? 'pointer' : 'default' }}>
                      <i className="mdi mdi-video font-size-24 " />
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row>
                <Col >
                  <div className="avatar-sm rounded-circle bg-success align-self-center mini-stat-icon ">
                    <span className='avatar-title rounded-circle bg-success'>
                      <i className="bx bx-archive-in font-size-24" ></i>
                    </span>
                  </div>
                  <span className='text-muted'>{details?.RouteDetail.R_Play} Duration (min)</span>
                </Col>
                <Col>
                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                    <span className='avatar-title rounded-circle bg-primary'>
                      <i className="bx bx-archive-in font-size-24" />
                    </span>
                  </div>
                  <span className='text-muted'>{details?.RouteDetail.R_today} Downloaded Today</span>
                </Col>
              </Row>
              <br />
              <Row>
                <h5>Owner : <span style={{ textWrap: "nowrap" }}>{details?.RouteDetail.Own}</span></h5>
                <h5>InCharge : <span style={{ textWrap: "nowrap" }}>{details?.RouteDetail.Incharge}</span></h5>
                <h5>InCharge Mobile No:<span style={{ textWrap: "nowrap" }}> {details?.RouteDetail.In_mob}  </span></h5>
                <h5>Driver Name: <span style={{ textWrap: "nowrap" }}>{details?.RouteDetail.Driver}</span> </h5>
                <h5>Driver Mobile No: <span style={{ textWrap: "nowrap" }}> {details?.RouteDetail.DrMobile} </span></h5>
                <Row>
                  <Col><a className='link' onClick={showDetail} >Bus Details</a></Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={modal} className='modal-lg' role="dialog" autoFocus={true} centered data-toggle="modal"
        toggle={() => { setModal(!modal); }} >
        <img src={img_src} onError={(e) => { resetImage(e) }} />
      </Modal>
      <Modal isOpen={isLiveStreamOpen} style={{ maxWidth: "730px" }} toggle={() => { setIsLiveStreamOpen(!isLiveStreamOpen) }} >
        <ModalHeader toggle={() => { setIsLiveStreamOpen(!isLiveStreamOpen) }}>Live Stream</ModalHeader>
        <Rtsp rtsp={rtsp} />
      </Modal>
      <Modal isOpen={isshowDetailOpen} style={{ maxWidth: "650px" }} toggle={() => { setIsShowDetailOpen(!isshowDetailOpen) }}>
        <ModalHeader toggle={() => { setIsShowDetailOpen(!isshowDetailOpen) }}>
          <Row>
            <Col>Bus/Route Details</Col>
          </Row>
        </ModalHeader>
        <Bus_Detail_Form Route={route} NewBus={false} AddCam={false} Bus_Nameplate={Bus_Nameplate} />
      </Modal>
    </React.Fragment>
  )
}

export default RouteDetail
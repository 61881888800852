import { add_form_api} from "helpers/backend_helper";
import {   GET_ADD_FORM_DATA,ADD_FORM_API_FAIL } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import { addFormApiFail,addFormApiSuccess} from "./actions";


function* getAddFormApiData({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(add_form_api,queryParam);
        console.log("response"+response);
        if(response.error)
        {
            console.log("errorrrr")
            yield put(addFormApiFail(ADD_FORM_API_FAIL, response.error));    
        }
        else{
            yield put(addFormApiSuccess(response));
        }
        // localStorage.setItem("subgroups_details",response?.OK)
        callback && callback(response)
    } catch (error) {
        yield put(addFormApiFail(ADD_FORM_API_FAIL, error));
    }
}


function* addFormSaga() {
    yield takeEvery(GET_ADD_FORM_DATA, getAddFormApiData);
}


export default addFormSaga;

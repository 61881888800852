import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import { error } from "toastr";
import { detail_data } from "common/data/detail_dashboard";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = "https://remotedrishtee.com/api/";

const errorCode = [1, 2];

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => {
      if (errorCode.includes(response.data?.error?.code)) {
        if (response.data?.error?.code === 1)
          Logout(response.data?.error?.msg);
        
        AlertError(response.data?.error?.msg );
      } 
        return response.data;
    })
    .catch(error => {
      if (error?.response?.data?.error?.msg) {
        // Logout(error.response.data.msg);
        AlertError(error?.response?.data?.error?.msg);
        console.log("Error in get call ", error);
      }
      throw error;
    });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

function Logout(error) {
  window.localStorage.clear();
  window.location.replace('/login');
}

function AlertError(error) {
  // window.alert(error);
  console.log("Api Error Response",error);
}
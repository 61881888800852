import {
    LIVE_CAMERA_DATA_API_FAIL,
    LIVE_CAMERA_DATA_API_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
    data: {},
};

const Live_Camera_Data = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIVE_CAMERA_DATA_API_SUCCESS:
            return {
                ...state,
                data: action.payload.OK
            };

        case LIVE_CAMERA_DATA_API_FAIL:
            return {
                ...state,
                apiDataError: action.payload.error
            };

        default:
            return state;
    }
}

export default Live_Camera_Data;
import { snap_shot_api} from "helpers/backend_helper";
import {   GET_SNAP_SHOT_DATA,SNAP_SHOT_API_FAIL } from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { snapshotApiFail,snapshotApiSuccess} from "./actions";





function* getSnapShotApiData({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(snap_shot_api,queryParam);
        console.log("response"+response);
        if(response.error)
        {
            console.log("errorrrr")
            yield put(snapshotApiFail(SNAP_SHOT_API_FAIL, response.error));    
        }
        else{
            yield put(snapshotApiSuccess(response));
        }
        // localStorage.setItem("subgroups_details",response?.OK)
        callback && callback(response)
    } catch (error) {
        yield put(snapshotApiFail(SNAP_SHOT_API_FAIL, error));
    }
}


function* snapShotSaga() {
    yield takeEvery(GET_SNAP_SHOT_DATA, getSnapShotApiData);
}


export default snapShotSaga;

import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { Alert, Button, Card, Col, Form, Input, Label, Row, Table } from 'reactstrap';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { getaddFormData, getmacId } from 'store/actions';
import Bus_Detail_Form from './Bus_Detail_form';
import toastr from "toastr";
import { handleSearchData } from 'components/Common/searchFile';


const AddModifyCamDetail = ({ Route, NewBus, AddCam, Mac, Ip, Bus_Nameplate }) => {
    const user = useSelector((state) => state.Login.user);
    const sid = user.sessionID;
    const dispatch = useDispatch();
    const cam_type_list = useSelector((state) => state.AddCam_Detail?.data?.Cam_type);
    const ip_add_list = useSelector((state) => state.AddCam_Detail?.data?.IP_Add);
    const location_list = useSelector((state) => state.AddCam_Detail?.data?.location);
    const [newBus_Nameplate, setNewBus_Nameplate] = useState(null);
    const [submitCamera, setSubmitCamera] = useState(false);
    const [mac, setMac] = useState(Mac ? Mac : "");
    const [ip,setIp] = useState(Ip ? Ip :"");
    
    const bus_Nameplate = Bus_Nameplate;
    toastr.options = {
        positionClass: "toast-top-center",
        progressBar: "progressBar",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: 2000,
    };
    const camValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Bus_Nameplate: NewBus ? newBus_Nameplate : bus_Nameplate || '',
            Cam_Type: '',
            IPAddress: ip || '',
            Camera_MAC_ID: mac || '',
            Location: '',
            Downloads: true,
            VideoRecords: true,
            Images: true,
            LiveVideo: true,
            Notification: true,
            DeviceActive: true,
        },
        validationSchema: Yup.object({
            Bus_Nameplate: Yup.string().required('Please Enter Bus Nameplate').matches(/.*\d{4}$/, 'Please Provide Valid Bus Nameplate'),
            IPAddress: Yup.string()
                .matches(
                    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    'Please Select Valid IP Address'
                )
                .required('Please Select Your IP Address'),
            Camera_MAC_ID: Yup.string()
                .matches(/^[0-9a-fA-F]{2}([\.:-])(?:[0-9a-fA-F]{2}\1){4}[0-9a-fA-F]{2}$/, 'Please Enter Valid MAC Address.')
                .required('Please Search Your MAC ID'),
            Cam_Type: Yup.string().required('Please Select Cam Type'),
            Location: Yup.string().required('Please Select Location'),
        }),
        onSubmit: (values) => {
            // setNewBus_Nameplate(values.Bus_Nameplate);
            let queryParam = {
                sid: sid,
                id: 1,
                p1: NewBus ? values.Bus_Nameplate : bus_Nameplate,
                p2: values.Camera_MAC_ID,
                p3: ip,
                p4: `${values.Downloads ? 1 : 0}${values.VideoRecords ? 1 : 0}${values.Images ? 1 : 0}${values.LiveVideo ? 1 : 0}${values.Notification ? 1 : 0}${values.DeviceActive ? 1 : 0}00${values.Cam_Type}${values.Location}`,
                p5: AddCam ? 0 : 1,
            };
            dispatch(getaddFormData(queryParam, (response) => {
                if (response?.OK) {
                    setSubmitCamera(true);
                    toastr.success(response?.OK.msg);
                }
                if (response?.error) {
                    toastr.error(response?.error.msg);
                }
            }));
        },
    });
    const handleSearchMacId = () => {
        setIp(camValidation.values.IPAddress);
        if (camValidation.values.IPAddress) {
            let queryParam = {
                sid: sid,
                ip: !Mac ? camValidation.values.IPAddress : Ip,
            }
            dispatch(getmacId(queryParam, (response) => {
                if (response?.OK) {
                    setMac(response?.OK.data);
                    console.log(camValidation.values.IPAddress);
                    
                }
                if (response?.Err) {
                    toastr.error(response?.Err.msg)
                    setMac("");
                }
            }));
        }
        else {
            toastr.warning("please first select IP Address")
        }
    }

    useEffect(() => {
        if(NewBus){
            setNewBus_Nameplate(camValidation.values.Bus_Nameplate)
        }
        setIp(camValidation.values.IPAddress);
        if(!Mac){
            setMac("");
        }
    },[camValidation.values.IPAddress])
    return (
        <>
            {
                !submitCamera ?
                    <Form onSubmit={camValidation.handleSubmit}>
                        <Row className='p-3 pt-1'>

                            <div>
                                <Row>
                                    {NewBus ?
                                        <Col>
                                            <div className="mb-3">
                                                <Label className="form-label">Bus Nameplate</Label>
                                                <Input
                                                    name="Bus_Nameplate"
                                                    className="form-control"
                                                    placeholder="Enter Bus Nameplate"
                                                    type="text"
                                                    onChange={camValidation.handleChange}
                                                    onBlur={camValidation.handleBlur}
                                                    value={camValidation.values.Bus_Nameplate || ""}
                                                    invalid={
                                                        camValidation.touched.Bus_Nameplate && camValidation.errors.Bus_Nameplate ? true : false
                                                    }
                                                    autoComplete="off"
                                                />
                                                {camValidation.touched.Bus_Nameplate && camValidation.errors.Bus_Nameplate ? (
                                                    <Alert color="danger">{camValidation.errors.Bus_Nameplate}</Alert>
                                                ) : null}
                                            </div>
                                        </Col> : <></>
                                    }

                                    <Col className='col-6'>
                                        <div className="mb-3">
                                            <Label className="form-label">IP Address</Label>
                                            {
                                                Mac ?
                                                    <Input
                                                        name="IPAddress"
                                                        className="form-control"
                                                        type="text"
                                                        value={Ip}
                                                        disabled />
                                                    :
                                                    <select
                                                        className="form-select"
                                                        name="IPAddress"
                                                        onChange={camValidation.handleChange}
                                                        onBlur={camValidation.handleBlur}
                                                        value={camValidation.values.IPAddress}>
                                                        <option value="" disabled hidden>Select IP Address</option>
                                                        {ip_add_list.map((item, index) => (
                                                            <option value={item} key={index}>{item}</option>
                                                        ))}
                                                    </select>
                                            }

                                            {camValidation.touched.IPAddress && camValidation.errors.IPAddress ? (
                                                <Alert color="danger">{camValidation.errors.IPAddress}</Alert>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col className='col-6'>
                                        <Label className="form-label">MAC ID</Label>
                                        <Row>
                                            <Col sm={8} className='pe-0'>
                                                <div className="mb-3">
                                                    <Input
                                                        name="Camera_MAC_ID"
                                                        className="form-control"
                                                        placeholder="Search MAC ID"
                                                        type="text"
                                                        onChange={camValidation.handleChange}
                                                        onBlur={camValidation.handleBlur}
                                                        value={mac}
                                                        invalid={camValidation.touched.Camera_MAC_ID && camValidation.errors.Camera_MAC_ID}
                                                        autoComplete="off"
                                                        disabled />
                                                    {camValidation.touched.Camera_MAC_ID && camValidation.errors.Camera_MAC_ID ? (
                                                        <Alert color="danger">{camValidation.errors.Camera_MAC_ID}</Alert>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col className='col-4 p-0'>
                                                <Button color="primary" onClick={(e) => { e.preventDefault(); handleSearchMacId(); }} outline>Search MAC</Button>
                                            </Col>
                                            {/* {
                                                !Mac ?
                                                    <Col className='col-4 p-0'>
                                                        <Button color="primary" onClick={(e) => { e.preventDefault(); handleSearchMacId(); }} outline>Search MAC</Button>
                                                    </Col>
                                                    : <></>
                                            } */}
                                        </Row>
                                    </Col>
                                    <Col className='col-6'>
                                        <div className="mb-3">
                                            <Label className="form-label">Cam Type</Label>
                                            <select
                                                className="form-select"
                                                name="Cam_Type"
                                                onChange={camValidation.handleChange}
                                                onBlur={camValidation.handleBlur}
                                                value={camValidation.values.Cam_Type}
                                            >
                                                <option value="" disabled hidden>Select Cam Type</option>
                                                {cam_type_list.map((item, index) => (
                                                    <option value={index} key={index}>{item}</option>
                                                ))}
                                            </select>
                                            {camValidation.touched.Cam_Type && camValidation.errors.Cam_Type ? (
                                                <Alert color="danger">{camValidation.errors.Cam_Type}</Alert>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col className='col-6 pe-0'>
                                        <div className="mb-3">
                                            <Label className="form-label">Location</Label>
                                            <select
                                                className="form-select"
                                                name="Location"
                                                onChange={camValidation.handleChange}
                                                onBlur={camValidation.handleBlur}
                                                value={camValidation.values.Location}
                                            >
                                                <option value="" disabled hidden>Select Loction</option>
                                                {location_list.map((item, index) => (
                                                    <option value={index} key={index}>{item}</option>
                                                ))}
                                            </select>
                                            {camValidation.touched.Location && camValidation.errors.Location ? (
                                                <Alert color="danger">{camValidation.errors.Location}</Alert>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label className="form-label">Cam Permissions</Label>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>Downloads</th>
                                                    <th>Video Records</th>
                                                    <th>Images</th>
                                                    <th>Live Video</th>
                                                    <th>Notification</th>
                                                    <th>Device Active</th   >
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {["Downloads", "VideoRecords", "Images", "LiveVideo", "Notification", "DeviceActive"].map((permission, index) => (
                                                        <td key={index}>
                                                            <div className="form-check mb-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name={permission}
                                                                    checked={camValidation.values[permission]}
                                                                    onChange={camValidation.handleChange}
                                                                    onBlur={camValidation.handleBlur}
                                                                    id={`defaultCheck${index + 1}`}
                                                                />
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Row>
                            </div>
                        </Row>
                        <Row className='text-center'>
                            <Col className='pb-3'>
                                <button type='submit' className='btn btn-primary'>
                                    {AddCam || NewBus ? 'Add Camera' : 'Update Camera'}
                                </button>
                            </Col>
                        </Row>
                    </Form>
                    :
                    <Bus_Detail_Form Route={NewBus ? null : Route} NewBus={NewBus} AddCam={AddCam} Bus_Nameplate={newBus_Nameplate} />
            }
        </>
    );
};

export default AddModifyCamDetail;

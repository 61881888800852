import { subgroups_api} from "helpers/backend_helper";
import {   GET_SUB_GROUP_DATA, SUB_GROUP_API_SUCCESS, SUB_GROUP_API_FAIL } from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { detailApiFail, subgroupsApiFail, subgroupsApiSuccess } from "./actions";





function* getSubGroupsApiData({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(subgroups_api,queryParam);
        console.log("response"+response);
        yield put(subgroupsApiSuccess(response));
        // localStorage.setItem("subgroups_details",response?.OK)
        callback && callback(response)
    } catch (error) {
        yield put(subgroupsApiFail(SUB_GROUP_API_FAIL, error));
    }
}


function* subGroupsSaga() {
    yield takeEvery(GET_SUB_GROUP_DATA, getSubGroupsApiData);
}


export default subGroupsSaga;

import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postLogin, userLogout } from "helpers/backend_helper";
import { jwtDecode } from "jwt-decode";
import { data } from "autoprefixer";
import { error } from "toastr";



function* loginUser({ payload: { user, history ,callback} }) {
  try {
    
    if (process.env.REACT_APP_DEFAULTAUTH === "app") {
      var response = yield call(postLogin, user);
      if(response?.error){
        console.log("Error in login", response.error?.msg)
        yield put(apiError(response?.error?.msg));
        callback && callback(response)
      }
      if(response?.OK){
        let token = response?.OK?.data?.token;
        let userEncoded = response?.OK?.data?.data;
        response = jwtDecode(token);
        let userData = jwtDecode(userEncoded);
        localStorage.setItem("authUser", JSON.stringify(token));
        localStorage.setItem("userData", JSON.stringify(userEncoded));
        yield put(loginSuccess(response,userData));
        history('/summary_dashboard');
        callback && callback(response)
      }

    }
  } catch (error) {
    console.log("Login Catch SAGA ",error)
    let errormsg = [error?.response?.data?.error?.msg];
    yield put(apiError(errormsg));
  }
}

function* logoutUser({ payload: { sid, history } }) {
  try {
    localStorage.removeItem("authUser");
    const response = yield call(userLogout, sid);
    yield put(logoutUserSuccess(response));
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card, CardHeader, Col, Container, Row, Spinner, Toast, ToastBody, ToastHeader } from 'reactstrap'
import Bus_Recording from './Bus_Recording';
import Attendance from './Attendence';
import GpsStatus from './GpsStatus';
import CardUser from "./CardUser";
import { useDispatch } from 'react-redux';
import { getSummaryChartsData, getSummaryData, setIsLoading } from 'store/actions';

function Summary_Dashboard() {
    const dispatch = useDispatch();

    const sid = useSelector(state => state.Login.user?.sessionID)
    const startDate = useSelector(state => state.Active_Store?.dates?.startDate)
    const endDate = useSelector(state => state.Active_Store?.dates?.endDate);
    const group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)
    const BusRecord = useSelector(state => state.Summary_Dashboard.summaryData?.BusRecordings)
    const GpsRecord = useSelector(state => state.Summary_Dashboard.summaryData?.GPS)
    const AttendenceRecord = useSelector(state => state.Summary_Dashboard.summaryData?.Attendance)
    const isLoading = useSelector(state => state.Summary_Dashboard?.isLoading);
    const noDataError = useSelector(state => state.Summary_Dashboard?.apiDataError?.error?.msg);
    console.log(noDataError);
    console.log("Summary Dashboard");
    const ref = useRef(null);

    useEffect(() => {
        ref.current = setInterval(async () => {
            console.log("Interval Calls Main", ref.current);
            let queryParams = {
                sid: sid,
                date: startDate,
                enddate: endDate,
                route: group,
            }
            dispatch(getSummaryData(queryParams));
            dispatch(setIsLoading(true));
            dispatch(getSummaryChartsData(queryParams));
        }, 180000);
        return () => {
            if (ref.current) {
                clearInterval(ref.current)
            }
        }

    }, [startDate, endDate, group]);
    document.title = "Summary Dashboard";
    return (
        <React.Fragment>
            <div className="page-content mt-3">
                <Container fluid >
                    <>

                        <Row >
                            <Col sm={4}><Bus_Recording Bus_Recording={BusRecord} /></Col>
                            <Col sm={4}><GpsStatus GPS={GpsRecord} /></Col>
                            <Col sm={4}><Attendance Attendance={AttendenceRecord} /></Col>
                        </Row>
                        <Row>
                            {
                                isLoading ?
                                    <>
                                        <div className="d-flex flex-wrap">
                                            <h5 className="card-title me-2">Daily Line Chart</h5>
                                        </div>
                                        <Spinner color="primary" className="position-absolute start-50" />
                                    </>
                                    :
                                    noDataError === undefined ?
                                        <CardUser dataColors='["--bs-primary", "--bs-warning","--bs-danger"]' /> :
                                        <div>{noDataError}</div>
                            }
                        </Row>
                    </>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Summary_Dashboard
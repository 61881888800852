import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { Alert, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getaddFormData } from 'store/actions';
import toastr from "toastr";

const Holiday_Form = () => {
    const holiday_List = useSelector(state => state.AddCam_Detail?.data?.Holiday);
    const group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)
    const [fm_Date, setFm_Date] = useState("");
    const [reason, setReason] = useState("");
    const [to_Date, setTo_Date] = useState("");
    const [holiday, setHoliday] = useState("");
    const [update, setUpdate] = useState(false);
    const user = useSelector(state => state.Login.user);
    const sid = user.sessionID;
    const dispatch = useDispatch();
    function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    toastr.options = {
        positionClass: "toast-top-center",
        progressBar: "progressBar",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: 2000,
    };
    const validation = useFormik({
        // enableReinitialize : use this  flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            FromDate: fm_Date ? fm_Date : "" || '',
            TODate: to_Date ? to_Date : "" || '',
            Remarks: reason ? reason : "" || '',
            Holiday: holiday ? holiday : "" || '',
        },
        validationSchema: Yup.object({
            FromDate: Yup.string().required("Please Select Date"),
            TODate: Yup.string().required("please select Date"),
            Remarks: Yup.string().required("Please Enter Remarks"),
            Holiday: Yup.string().required("please select holiday type"),

        }),
        onSubmit: (values) => {
            let queryParam = {
                sid: sid,
                id: update ? 4 : 2,
                p1: values.FromDate,
                p2: values.TODate,
                p3: values.Holiday,
                p4: values.Remarks,
                p5: group,
            }
            dispatch(getaddFormData(queryParam, (response) => {
                if (response?.OK) {
                    setFm_Date("");
                    setTo_Date("");
                    setReason("");
                    setHoliday("");
                    setUpdate(false);
                    toastr.success(response?.OK?.msg);
                }
            }));

        }
    });
    useEffect(() => {
        if (validation.values.Holiday) {
            const queryParam = {
                sid: sid,
                id: 3,
                p1: "",
                p2: "",
                p3: validation.values.Holiday,
                p4: "",
                p5: group,
            };
            dispatch(getaddFormData(queryParam, (response) => {
                if (response?.OK) {
                    let data = response.OK?.data;
                    setFm_Date(data.Fm_Date);
                    setTo_Date(data.To_Date);
                    setReason(data.Reason);
                    setHoliday(data.Holiday);
                    setUpdate(true);
                }
                else {
                    toastr.warning("This Holiday Does not exits in System ! Add Holiday !!");
                    setFm_Date("");
                    setTo_Date("");
                    setReason("");
                    setHoliday("");

                }
            }));
        }
    }, [validation.values.Holiday, dispatch]);


    return (
        <Form onSubmit={validation.handleSubmit}>
            <Row className='p-3'>
                <Col className="col-12">
                    <Row>
                        <Col className="col-6">
                            <div className="mb-3">
                                <Label className="form-label">Holiday</Label>
                                <select className="form-select" name="Holiday"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Holiday || ""}>
                                    <option value="" disabled hidden>Select Holiday</option>
                                    {holiday_List && holiday_List.map((item, index) => (
                                        <option value={item} key={index}>{item}</option>
                                    ))}

                                </select>
                                <div>
                                    {validation.touched.Holiday && validation.errors.Holiday ? (
                                        <Alert color="danger">{validation.errors.Holiday}</Alert>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-6">
                            <div className="mb-3">
                                <Label className="form-label">From Date</Label>
                                <Input
                                    name="FromDate"
                                    className="form-control"
                                    placeholder="Select Date  "
                                    type="date"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    min={getCurrentDateTime()}
                                    value={validation.values.FromDate || ""}
                                    invalid={
                                        validation.touched.FromDate && validation.errors.FromDate ? true : false
                                    } />
                                {validation.touched.FromDate && validation.errors.FromDate ? (
                                    <Alert color="danger">{validation.errors.FromDate}</Alert>
                                ) : null}
                            </div>
                        </Col>
                        <Col className="col-6">
                            <div className="mb-3">
                                <Label className="form-label">To Date</Label>
                                <Input
                                    name="TODate"
                                    className="form-control"
                                    placeholder="Select Date"
                                    type="date"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    min={getCurrentDateTime()}
                                    value={validation.values.TODate || ""}
                                    invalid={
                                        validation.touched.TODate && validation.errors.TODate ? true : false
                                    } />
                                {validation.touched.TODate && validation.errors.TODate ? (
                                    <Alert color="danger">{validation.errors.TODate}</Alert>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <div className="mb-3">
                                <Label className="form-label">Remarks</Label>
                                <Input
                                    name="Remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    type="textarea"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Remarks || ""}
                                    invalid={
                                        validation.touched.Remarks && validation.errors.Remarks ? true : false
                                    }
                                    autoComplete="off" />
                                {validation.touched.Remarks && validation.errors.Remarks ? (
                                    <Alert color="danger">{validation.errors.Remarks}</Alert>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className='text-center pb-3'>
                    <button type="submit" className='btn btn-primary'>{update ? "Update" : "Submit"}</button>
                </Col>
            </Row>
        </Form>
    );
};

export default Holiday_Form;

import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu

import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import RouteTab from "components/RouteTab";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDetailData, getRouteList, getSummaryChartsData, getSummaryData, getsubgroupsData, setIsLoading, setSubGroup } from "store/actions";
import moment from "moment";
import HierarchyList from "./HierarchyList";

const SidebarContent2 = props => {
    const ref = useRef();
    const dispatch = useDispatch();

    const user = useSelector(state => state.Login.user)
    const group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)
    const [activeItem, setActiveItem] = useState(null);
    const dates = useSelector(state => state.Active_Store?.dates);
    const sid = user.sessionID;
    const currentSubGroup = useSelector(state => state.Active_Store?.sub_group);
    const [newListObject, setNewList] = useState({});
    let startDate = dates?.startDate;
    let endDate = dates?.endDate;

    const detail_store = useSelector(state => state.Detail_Dashboard?.data);



    const [routeList,setRouteList] = useState([]);
    const [sub_groups, setSubGroups] = useState([]);
    const handleGroupClick = (item) => {
        console.log("Sub Group Click Event", item);

        let summaryQueryParams = {
            sid: sid,
            date: startDate,
            enddate: endDate,
            route: item,
        }
        setActiveItem(null);
        dispatch(setSubGroup(item));
        dispatch(getSummaryData(summaryQueryParams));
        dispatch(setIsLoading(true));
        dispatch(getSummaryChartsData(summaryQueryParams));

    };

    const handleRouteClick = (event, routename) => {
        event.stopPropagation();
        console.log("Route Click Event", routename);
        setActiveItem(routename);
    }

    useEffect(() => {
        let summaryQueryParams = {
            sid: sid,
            date: startDate,
            enddate: endDate,
            route: group,
        }
        dispatch(getSummaryData(summaryQueryParams));
        dispatch(setIsLoading(true));
        dispatch(getSummaryChartsData(summaryQueryParams));

        let queryParamForSubGroup = {
            sid: sid,
            group: group,
        }
        dispatch(getsubgroupsData(queryParamForSubGroup, (response) => {

            let hierarchyData = response.OK.data;
            const hierarchyObject = buildHierarchy(hierarchyData);
            setNewList(hierarchyObject);
            console.log("NEw List", newListObject);

        }));
        let routeListQueryParam = {
            sid: sid,
            date: startDate,
            enddate: endDate,
            route: "",
            higroup: group,
        }

        dispatch(getDetailData(routeListQueryParam))

    }, [dispatch]);


    function buildHierarchy(data) {
        const hierarchyObject = {};

        function buildTree(groupId) {
            const group = data.find(item => item.Group_ID === groupId);
            if (!group) return null;

            const children = data.filter(item => item.Group_Head === groupId);

            const node = {
                Group_ID: group.Group_ID,
                Group_Name: group.Group_Name,
                Group_Head: group.Group_Head,
                Hierarchy: group.Hierarchy,
                Group_Type: group.Group_Type
            };

            if (children.length > 0) {
                node.children = children.map(child => buildTree(child.Group_ID));
            }

            return node;
        }

        // Identify the highest hierarchy level dynamically
        const highestHierarchyLevel = Math.max(...data.map(item => parseInt(item.Hierarchy, 10)));

        // Build the hierarchy for each root element
        data.filter(item => item.Hierarchy === highestHierarchyLevel.toString()).forEach(rootItem => {
            hierarchyObject[rootItem.Group_ID] = buildTree(rootItem.Group_ID);
        });

        return hierarchyObject;
    }

    useEffect(() => {
        setRouteList(detail_store?.data?.[0]);
    }, [detail_store]);

    useEffect(() => {
        console.log("Update Route list ", routeList);
        console.log("Update  list ", newListObject);
        if (routeList && newListObject) {
            setNewList((prev) => {
                let newlist = mergeRoutesIntoHierarchy({ ...prev }, routeList);
                return newlist;
            });
        }

    }, [routeList])



    function mergeRoutesIntoHierarchy(hierarchyObject, routes) {
        function mergeRoutes(node) {
            if (node.children) {
                node.children.forEach(child => mergeRoutes(child));
            }

            const matchingRoute = routes.find(route => route.RouteDetail.BusRoute === node.Group_ID);

            if (matchingRoute) {
                if (!node.routes || node?.routes?.length > 0) {
                    node.routes = [];
                }

                node.routes.push(matchingRoute);
            }
        }

        for (const rootGroupId in hierarchyObject) {
            mergeRoutes(hierarchyObject[rootGroupId]);
        }

        return hierarchyObject;
    }

    return (
        <React.Fragment>
            <SimpleBar className="h-100 simplebar-offset pt-4" ref={ref}>
                <div id="sidebar-menu">
                    {Object.keys(newListObject).map((rootGroupId, index) => (
                        <HierarchyList key={index}
                            node={newListObject[rootGroupId]}
                            activeItem={activeItem}
                            handleRouteClick={handleRouteClick}
                            handleGroupClick={handleGroupClick} />
                    ))}
                </div>

            </SimpleBar>
        </React.Fragment>
    );
};

SidebarContent2.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent2));

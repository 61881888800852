import RouteTab from 'components/RouteTab';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSummaryData, setSubGroup } from 'store/actions';

const HierarchyList = ({ node, activeItem,handleRouteClick,handleGroupClick  }) => {


  return (

    <ul className="metismenu list-unstyled" id="side-menu">
      <li>
        {
          !node.routes  && node.Hierarchy > 0 &&
          <Link to="/#" className="has-arrow px-3" onClick={()=>handleGroupClick(node.Group_ID)}>
            {node.Group_Name}
          </Link>
        }
        {node.routes && (
          <ul className="sub-menu" id="side-menu-1" >
            {node.routes.map((route, index) => (
              <li key={route.RouteDetail.Route} style={{ cursor: "pointer", color: activeItem === route.RouteDetail.Route ? '#90EE90' : 'white', fontWeight: activeItem === route.RouteDetail.Route ? 'bold' : 'normal' }}
                onClick={(e) => handleRouteClick(e, route.RouteDetail.Route)}
              >
                <RouteTab route={route} sub_group={node.Group_Head} /></li>
            ))}
          </ul>
        )}
        {node.children && (
          <ul>
            {node.children.map((child, index) => (
              <HierarchyList key={index} node={child} activeItem={activeItem} handleRouteClick={handleRouteClick} handleGroupClick={handleGroupClick} />
            ))}
          </ul>
        )}
      </li>
    </ul>
  );
}
export default HierarchyList;

import moment from "moment";
import {
    SET_DATES,
    GET_ACTIVE_CAMERA,
    SET_ACTIVE_SUB_GROUP,
} from "./actionTypes";

const INIT_STATE = {
    dates: {
        startDate : moment().startOf('month').format("YYYY-MM-DD"),
        endDate : moment().add(1,"days").format("YYYY-MM-DD")
  
    },
    activeCamera: "",
    macAddress: "",
    liveStatus: false,
    sub_group: "",
};

const Active_Store = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_DATES:
            return {
                ...state,
                dates: action.payload
            };
        case GET_ACTIVE_CAMERA:
            return {
                ...state,
                activeCamera: action.payload.activeCamera,
                macAddress: action.payload.macAddress,
                liveStatus: action.payload.liveStatus,
                
            };
        case SET_ACTIVE_SUB_GROUP:
            return {
                ...state,
                sub_group: action.payload
            }

        default:
            return state;
    }
}

export default Active_Store;
import {   
    ADD_FORM_API_SUCCESS,
    ADD_FORM_API_FAIL
} from "./actionTypes";

const INIT_STATE = {
    data: {},
};

const AddForm_Detail = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_FORM_API_SUCCESS:
            return {
                ...state,
                data: action.payload.OK
            };

        case ADD_FORM_API_FAIL:
            return {
                ...state,
                data: action.payload.error
            };

        default:
            return state;
    }
}

export default AddForm_Detail;
import { live_camera_api} from "helpers/backend_helper";
import { GET_LIVE_CAMERA_DATA, LIVE_CAMERA_DATA_API_FAIL,LIVE_CAMERA_DATA_API_SUCCESS}  from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getLiveCameraData,liveCameraDataApiSuccess,liveCameraDataApiFail} from "./actions";


function* getliveCameraDataApi({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(live_camera_api,queryParam);
        console.log("response"+response);
        yield put(liveCameraDataApiSuccess(response));
        //localStorage.setItem("live_data",response?.OK)
        callback && callback(response)
    } catch (error) {
        yield put(liveCameraDataApiFail(LIVE_CAMERA_DATA_API_FAIL, error));
    }
}


function* liveCameraDataSaga() {
    yield takeEvery(GET_LIVE_CAMERA_DATA, getliveCameraDataApi);
}


export default liveCameraDataSaga;

import { debug_screen_api} from "helpers/backend_helper";
import {   GET_DEBUG_SCREEN_DATA,DEBUG_SCREEN_API_FAIL } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import { debug_screenApiFail,debug_screenApiSuccess} from "./actions";


function* getDebug_ScreenApiData({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(debug_screen_api,queryParam);
        console.log("response"+response);
        if(response.error)
        {
            console.log("errorrrr")
            yield put(debug_screenApiFail(DEBUG_SCREEN_API_FAIL, response.error));    
        }
        else{
            yield put(debug_screenApiSuccess(response));
        }
        // localStorage.setItem("subgroups_details",response?.OK)
        callback && callback(response)
    } catch (error) {
        yield put(debug_screenApiFail(DEBUG_SCREEN_API_FAIL, error));
    }
}


function* debug_ScreenSaga() {
    yield takeEvery(GET_DEBUG_SCREEN_DATA, getDebug_ScreenApiData);
}


export default debug_ScreenSaga;

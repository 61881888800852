import {
    GET_DETAIL_DASHBOARD_DATA,
    DETAIL_DASHBOARD_API_SUCCESS,
    DETAIL_DASHBOARD_API_FAIL,
    
} from "./actionTypes";

export const detailApiSuccess = (data) => ({
    type: DETAIL_DASHBOARD_API_SUCCESS,
    payload: data ,
});

export const detailApiFail = (actionType, error) => ({
    type: DETAIL_DASHBOARD_API_FAIL,
    payload: { actionType, error },
});


export const getDetailData = (queryParam,callback) =>({
    type: GET_DETAIL_DASHBOARD_DATA,
    payload: queryParam,
    callback
})

// export const setActiveRoute = (payload) =>({
//     type: SET_ACTIVE_ROUTE,
//     payload
// })

import {
    ROUTE_LIST_DATA,
    ROUTE_LIST_API_SUCCESS,
    ROUTE_LIST_API_FAIL,
    SET_ACTIVE_ROUTE
} from "./actionTypes";

export const routeListApiSuccess = (data) => ({
    type: ROUTE_LIST_API_SUCCESS,
    payload: data ,
});

export const routeListApiFail = (actionType, error) => ({
    type: ROUTE_LIST_API_FAIL,
    payload: { actionType, error },
});


export const getRouteList = (queryParam,callback) =>({
    type: ROUTE_LIST_DATA,
    payload: queryParam,
    callback
})

export const setActiveRoute = (payload) =>({
    type: SET_ACTIVE_ROUTE,
    payload
})

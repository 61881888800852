import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Modal, ModalHeader, Row, Spinner, Table } from 'reactstrap';
import * as Yup from "yup";
import { useSelector } from 'react-redux';
import { getdebug_screenData } from 'store/actions';
import { useDispatch } from 'react-redux';
import Rtsp from './rtsp';
import moment from 'moment';

const Debug_Screen = () => {
    const user = useSelector(state => state.Login.user);
    const sid = user.sessionID;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isLiveStreamOpen, setIsLiveStreamOpen] = useState(false);
    const [debug_screen, setDebugScreen] = useState([]);
    const [myServer, setMyServer] = useState(0);
    const [ip_address, setIpAddress] = useState('');
    const [myNAS, setMyNAS] = useState(0);
    const [status, setStatus] = useState('');
    const [rtsp, setRtsp] = useState();
    const handleRefresh = () => {
        setIsLoading(true);
        let queryParam = {
            sid: sid,
            id: '1',
        }
        dispatch(getdebug_screenData(queryParam, (response) => {
            if (response?.OK) {
                setIsLoading(false);
            }
        }));
    }
    useEffect(() => {
        let queryParam = {
            sid: sid,
            id: '1',
        }
        dispatch(getdebug_screenData(queryParam, (response) => {
            if (response?.OK) {
                setIsLoading(false);
            }
        }));
    }, []);
    const debug_screen_data = useSelector(state => state.Debug_Screen_Detail?.data);
    useEffect(() => {
        if (debug_screen_data?.Router) {
            setDebugScreen(debug_screen_data?.Router[0]);
            setMyServer(debug_screen_data.ip_same);
            setMyNAS(debug_screen_data.NAS);
            setIpAddress(debug_screen_data.server_ip);
            setStatus(moment(debug_screen_data?.StatusAt).format("DD-MM-YYYY HH:mm:ss"));
        }
    }, [debug_screen_data])
    const livestream = (rtspurl) => {
        setIsLiveStreamOpen(!isLiveStreamOpen);
        setRtsp(rtspurl);
    };
    return (
        <div className="page-content mt-3 pb-0 mb-0">
            <React.Fragment>
                <Card>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4">Debug Screen</CardTitle>
                                        {
                                            isLoading ?
                                                <div>
                                                    <Spinner color="primary" className="position-absolute start-50" />
                                                </div>
                                                :
                                                <div className="table-responsive">
                                                    <Table className="table table-bordered  mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th>Recording Station</th>
                                                                {
                                                                    debug_screen && debug_screen.map((item, index) => (
                                                                        <th key={index}><center>{index + 1}</center></th>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>Name</th>
                                                                {
                                                                    debug_screen && debug_screen.map((item, index) => (
                                                                        <td key={index} className='text-center' style={{ color: 'black' }}>{item.Name}</td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>MAC</th>
                                                                {
                                                                    debug_screen && debug_screen.map((item, index) => (
                                                                        <td key={index} className='text-center' style={{ color: 'black' }}>{item.MAC}</td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>Voltage</th>
                                                                {
                                                                    debug_screen && debug_screen.map((item, index) => (
                                                                        <td key={index} className='text-center' style={{ color: item.v_flag === 1 ? "red" : "black" }}>{item.Volt}</td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>Temperature</th>
                                                                {
                                                                    debug_screen && debug_screen.map((item, index) => (
                                                                        <td key={index} className='text-center' style={{ color: item.t_flag === 1 ? "red" : "black" }}>{item.Temp}</td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr >
                                                                <th>My Server Working</th>
                                                                <td colSpan={debug_screen.length} className='text-center'>
                                                                    {

                                                                        myServer === 0 ?
                                                                            <i className="bx bxs-circle text-success me-1" />
                                                                            : <i className="bx bxs-circle bx-burst text-danger me-1" />
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>Status At</th>
                                                                <td colSpan={debug_screen.length} className='text-center' style={{ color: 'black' }}>{status}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>My NAS Working</th>
                                                                <td colSpan={debug_screen?.length} className='text-center'>
                                                                    {

                                                                        myNAS === "0" ?
                                                                            <i className="mdi mdi-circle text-success me-1" />
                                                                            : <i className="bx bxs-circle bx-burst text-danger me-1" />

                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>IP Address</th>
                                                                <td colSpan={debug_screen?.length} className='text-center' style={{ color: myServer === 1 ? "red" : "black" }}>  {ip_address}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Live View</th>
                                                                {
                                                                    debug_screen && debug_screen.map((item, index) => (

                                                                        <td key={index}><center><Button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => livestream(item.cam_live)}>Live Link</Button></center></td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <th>Devices</th>
                                                                {debug_screen !== null ?
                                                                    debug_screen && debug_screen.map((item, index) => (
                                                                        item.Cam !== null ?
                                                                            <td key={index}>
                                                                                {
                                                                                    item?.Cam?.map((detail, subIndex) => (
                                                                                        <table key={subIndex}>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <th>Bus_Detail {subIndex + 1}</th>
                                                                                                        <div>Cam : {detail.cam}</div>
                                                                                                        <div>IP : {detail.ip}</div>
                                                                                                        <div>
                                                                                                            Status :  <i className={`mdi mdi-circle ${detail['ip-color'] === 1 ? 'text-danger' : 'text-success'} me-1`} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    ))}
                                                                            </td>
                                                                            : <td key={index} className='text-center'>No Devices</td>
                                                                    )) : <td>No Devices </td>}
                                                            </tr>

                                                        </tbody>

                                                    </Table>
                                                    <div className='text-center pt-3'>
                                                        <button className='btn btn-primary' onClick={handleRefresh}>Refresh</button>
                                                    </div>
                                                </div>
                                        }
                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                <Modal isOpen={isLiveStreamOpen} style={{ maxWidth: "730px" }} toggle={() => { setIsLiveStreamOpen(!isLiveStreamOpen) }}>
                    <ModalHeader toggle={() => { setIsLiveStreamOpen(!isLiveStreamOpen) }}>Live Stream</ModalHeader>
                    <Rtsp rtsp={rtsp} />
                </Modal>
            </React.Fragment>
        </div>
    );
};

export default Debug_Screen;

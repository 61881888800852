import {
    GET_SNAP_SHOT_DATA,
    SNAP_SHOT_API_SUCCESS,
    SNAP_SHOT_API_FAIL,
    
} from "./actionTypes";

export const snapshotApiSuccess = (data) => ({
    type: SNAP_SHOT_API_SUCCESS,
    payload: data ,
});

export const snapshotApiFail = (actionType, error) => ({
    type: SNAP_SHOT_API_FAIL,
    payload: { actionType, error },
});


export const getsnapshotData = (queryParam,callback) =>({
    type: GET_SNAP_SHOT_DATA,
    payload: queryParam,
    callback
})


import {
    GET_SUMMARY_DATA,
    GET_SUMMARY_CHART_DATA,
    SUMMARY_DATA_API_SUCCESS,
    SUMMARY_DATA_API_FAIL,
    SET_LOADING,
} from "./actionTypes";

const INIT_STATE = {
    summaryData: {},
    summaryChartsData: {},
    isLoading: true
};

const Summary_Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUMMARY_DATA_API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_SUMMARY_DATA:
                    return {
                        ...state,
                        summaryData: action.payload.data.OK
                    };
                case GET_SUMMARY_CHART_DATA:
                    return {
                        ...state,
                        summaryChartsData: action.payload.data,
                        isLoading: false
                    };
                default:
                    return state;
            }
        case SUMMARY_DATA_API_FAIL:
                    return {
                        ...state,
                        apiDataError: action.payload
                    };
        case SET_LOADING:
                    return{
                        ...state,
                        isLoading: action.payload
                    }
        default:
            return state;
    }
}

export default Summary_Dashboard;
import getChartColorsArray from 'components/Common/ChartsDynamicColor';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardBody, CardHeader, Row } from 'reactstrap'

function Camera_Details_Status({ data }) {

    // console.log("Status of cam", data);

    const [series, setSeries] = useState([]);

    const getChartData = () => {

        let labels = ["Completed", "Inprogress", "Error", "Deleted"];
        let colors = getChartColorsArray('["--bs-success","--bs-primary", "--bs-danger","--bs-warning"]');

        return { labels, colors }
    }

    const chartData = getChartData(data);

    useEffect(() => {
        if (data) {
            const seriesData = [
                parseInt(data?.Recording, 10),
                parseInt(data?.Playlist, 10),
                parseInt(data?.Error, 10),
                parseInt(data?.Delete, 10)
            ];
            setSeries(seriesData)
        }
    }, [data])



    // console.log(chartData.series);

    const options = {
        labels: chartData.labels,
        colors: chartData.colors,
        legend: {
            show: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "65%",
                },
            },
        },
        responsive: [
            {
                breakpoint: 450,
                options: {
                    chart: {
                        height: 130,
                    },
                },
            },
            {
                breakpoint: 576,
                options: {
                    chart: {
                        height: 180,
                    },
                },
            },
            {
                breakpoint: 610,
                options: {
                    chart: {
                        height: 120,
                    },
                },
            },
            {
                breakpoint: 650,
                options: {
                    chart: {
                        height: 125,
                    },
                },
            },
            {
                breakpoint: 700,
                options: {
                    chart: {
                        height: 135,
                    },
                },
            },
            {
                breakpoint: 770,
                options: {
                    chart: {
                        height: 150,
                    },
                },
            },
            {
                breakpoint: 850,
                options: {
                    chart: {
                        height: 170,
                    }
                },
            }, {
                breakpoint: 1580,
                options: {
                    chart: {
                        height: 120,
                    }
                },
            }, {
                breakpoint: 1200,
                options: {
                    chart: {
                        height: 180,
                    }
                },
            },
        ],
    }


    return (
        <>
            <React.Fragment>
                <CardBody className='p-0'>
                    <div>

                        <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height="180"
                            className="apex-charts"
                        />
                    </div>
                    <div className="text-center text-muted">
                        <Row className='pb-0'>
                            <div className="mt-0 mb-2 text-truncate">
                                <i className="mdi mdi-circle text-success me-1" /> Completed
                            </div>
                        </Row>
                        <Row className='pb-0 pt-0'>
                            <div className="mt-0 mb-2 text-truncate">
                                <i className="mdi mdi-circle text-primary me-1" /> Inprogress
                            </div>
                        </Row>
                        <Row>
                            <div className="mt-0 mb-2 text-truncate">
                                <i className="mdi mdi-circle text-danger me-1" /> Error
                            </div>
                        </Row>
                        <Row>
                            <div className="mt-0 mb-2 text-truncate">
                                <i className="mdi mdi-circle text-warning me-1" /> Deleted
                            </div>
                        </Row>

                    </div>
                </CardBody>
            </React.Fragment>
        </>
    )
}

export default Camera_Details_Status
import {
    SET_DATES,
    GET_ACTIVE_CAMERA,
    SET_ACTIVE_SUB_GROUP,
} from "./actionTypes";

export const setDates = (payload) => ({
    type: SET_DATES,
    payload
});

export const getCameraActive = (payload) => ({
    type: GET_ACTIVE_CAMERA,
    payload
});

export const setSubGroup = (payload) => ({
    type: SET_ACTIVE_SUB_GROUP,
    payload
});




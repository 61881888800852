import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

const getLoggedInUser = () => {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    return null;
  };


  const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
  };

// Login Method
const postLogin = data => get(url.POST_LOGIN, {params:data});

// Logout
const userLogout = sid => get(url.LOGOUT_URL,{params:{"sid":sid}})

// Summary dashboard 

const summary_dashboard_data = queryParam => get(url.SUMMARY_DASHBOARD_0,{params:queryParam }) ;
const summary_dashboard_line_chart = queryParam => get(url.SUMMARY_LINE_CHART,{params:queryParam }) ;


const detail_dashboard_api_2 = queryParam => get(url.DETAILED_DASHBOARD_2,{params:queryParam }) ;
const data_table_api = queryParam => get(url.DATA_TABLE_API,{params:queryParam }) ;
const live_camera_api = queryParam => get(url.LIVE_CAMERA_DATA_API,{params:queryParam }) ;
const subgroups_api = queryParam => get(url.SUB_GROUPS_API,{params:queryParam }) ;
const snap_shot_api = queryParam => get(url.SNAP_SHOT_API,{params:queryParam }) ;
const add_cam_api = queryParam => get(url.ADD_CAM,{params:queryParam});
const add_form_api = queryParam => get(url.ADD_FORM,{params:queryParam});
const debug_screen_api = queryParam => get(url.DEBUG_SCREEN_API,{params:queryParam});
const search_mac_api = queryParam => get(url.SEARCH_MAC,{params:queryParam});
export {
    getLoggedInUser,
    isUserAuthenticated,
    postLogin,
    userLogout,
    summary_dashboard_data,
    summary_dashboard_line_chart,
    detail_dashboard_api_2,
    data_table_api,
    live_camera_api,
    subgroups_api,
    snap_shot_api,
    add_cam_api,
    add_form_api,
    debug_screen_api,
    search_mac_api,
  };
  
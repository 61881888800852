import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { logoutUser } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Logout = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const session = useSelector(state => state.Login.user)
  console.log(session.sessionID);
  const sid = session.sessionID;

  useEffect(() => {
    dispatch(logoutUser(sid,history));
  }, [dispatch, history]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Spinner } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";

const CardUser = ({ dataColors }) => {
  const apexCardUserChartColors = getChartColorsArray(dataColors);
  const dispatch = useDispatch();

  const sid = useSelector(state => state.Login.user?.sessionID)
  const startDate = useSelector(state => state.Active_Store?.dates?.startDate)
  const endDate = useSelector(state => state.Active_Store?.dates?.endDate);
  const group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)
  const [series, setSeries] = useState([]);
  // const [percentage,setPercentage] = useState([]);
  const [options, setOption] = useState({});

  const data = useSelector(state => state.Summary_Dashboard?.summaryChartsData);
  
  // const noDataError = useSelector(state => state.Summary_Dashboard?.apiDataError?.error?.msg);

  console.log("line chart data", data);
  const categories = [];
  const recording = [];
  const attendance = [];
  const distance = [];
  const present = [];
  const dlpercent = [];
  const pervehicle = [];

  const setChartData = () => {

    let currentDate = moment(startDate);
    const formattedEndDate = moment().add(1,"days");

    while (currentDate.isSameOrBefore(formattedEndDate, 'day')) {
      categories.push(currentDate.format('YYYY-MM-DD'));
      let dr = data?.DayRecordings?.find(r => currentDate.isSame(r.Recording?.Date));
      let dd = data?.GPSDistance?.find(d => currentDate.isSame(d.BusGPS?.Date));
      let da = data?.DayAttendance?.find(a => currentDate.isSame(a?.Date));
      if (dd) {
        distance.push(dd.BusGPS?.Distance);
      }
      else {
        distance.push(0);
      }
      if (dr) {
        recording.push(dr.Recording.Files);
      } else {
        recording.push(0);
      }
      if (da) {
        attendance.push(da['Present%'])
      }
      else {
        attendance.push(0);
      }
      currentDate = currentDate.add(1, 'days');
    }

    console.log(categories);
    console.log(recording);
    console.log(distance);
    console.log(attendance);
  }
  useEffect(() => {
    setChartData();
    let chartOptions = { ...option };
    let series = [
      {
        name: "Recording",
        data: (recording),
      },
      {
        name: "Attendance",
        data: (attendance),
      },
      {
        name: "Distance",
        data: (distance),
      },
    ];
    setSeries(series);
    chartOptions['xaxis'] = {
      type: "date",
      categories: categories.sort(),
      labels: {
        show: true,
        format: "dd MMM"
      }
    }
    console.log();
    setOption(chartOptions);
  }, [data])



  const option = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      show: true,
      format: 'dd MMM',
    },
    yaxis: [{
      seriesName: "Recording",
      axisBorder: {
        show: true,
        color: '#3B71CA'
      },
      title: {
        text: "Recording(Files)",
      },

    }, {
      seriesName: 'Attendance',
      labels: {
        formatter: function (value) {
          return value + '%'; // Format the label to append '%' symbol
        }
      },
      axisBorder: {
        show: true,
        color: '#f1b44c',
      },
      title: {
        text: "Attendance(Present%)",
      },
    }, {
      seriesName: 'Distance',
      opposite: true,
      axisBorder: {
        show: true,
        color: 'red',
      },
      title: {
        text: "Distance",
      },
    }],
    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardHeader>
          <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Daily Line Chart</h5>
            </div>
          </CardHeader>
          <CardBody>
            
              <div id="area-chart" dir="ltr">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height={300}
                  className="apex-charts"
                />
              </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardUser.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any
};

export default CardUser;
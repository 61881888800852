import { search_mac_api} from "helpers/backend_helper";
import {   GET_MAC_ID,SEARCH_MAC_API_FAIL } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects";
import { searchMacApiFail,searchMacApiSuccess} from "./actions";


function* getSearchMacId({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(search_mac_api,queryParam);
        console.log("response"+response);
        if(response.error)
        {
            console.log("errorrrr")
            yield put(searchMacApiFail(SEARCH_MAC_API_FAIL, response.error));    
        }
        else{
            yield put(searchMacApiSuccess(response));
        }
        callback && callback(response)
    } catch (error) {
        yield put(searchMacApiFail(SEARCH_MAC_API_FAIL, error));
    }
}


function* searchMacSaga() {
    yield takeEvery(GET_MAC_ID, getSearchMacId);
}


export default searchMacSaga;

import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Row, Tooltip } from 'reactstrap';
import { getLiveCameraData } from 'store/actions';
function LiveCamera({ details }) {

    const user = useSelector(state => state.Login.user);
    const liveCameraData = useSelector(state => state.Live_Camera_Data?.data);
    const [chartsData, setChartData] = useState([]);
    const dispatch = useDispatch();
    const [speedoflivedata, setspeed] = useState(false);

    const getSpeedFromLiveCamaraData = (cam) => {
        let speed = "N/A";

        details?.RouteDetail?.Cam_Details?.map((s) => {
            if (s.Cam === cam)
                speed = s?.speed;
        })
        liveCameraData?.data?.map((item) => {
            if (item.live.bus === cam) {
                speed = item.live?.["Dnld_Speed mbps"];
            }
        });
        return speed;
    }
    const getDataWithDates = (lGraph) => {
        let dateArray = [];
        let data = [];
        let sortedGraph = lGraph.sort((a, b) => moment(a.time) - moment(b.time));
        console.log(sortedGraph);
        let currentDate = moment(sortedGraph[0].time).clone().subtract(2, 'm').format("YYYY-MM-DD HH:mm:ss");
        const formattedEndDate = moment(sortedGraph[lGraph.length - 1].time).add(2, 'm').format("YYYY-MM-DD HH:mm:ss");
        console.log("end date", formattedEndDate);
        console.log("start date", currentDate);

        while (moment(currentDate).isSameOrBefore(formattedEndDate)) {
            dateArray.push(currentDate);
            let df = sortedGraph.find(val => moment(currentDate).isSame(val.time));
            if (df) {
                data.push(parseInt(df.files));
            } else {
                data.push(0);
            }

            currentDate = moment(currentDate).add(1, 'm').format("YYYY-MM-DD HH:mm:ss");
        }

        return { data, dateArray };
    }

    const options = {
        chart: { sparkline: { enabled: !0 } },
        stroke: { curve: "smooth", width: 2 },
        colors: ["#3B71CA"],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.6,
                opacityTo: 0.05,
                stops: [25, 100, 100, 100],
            },
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
            shared: true,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            custom: undefined,
            hideEmptySeries: true,
            fillSeriesColor: false,
            theme: false,
            style: {
                fontSize: '12px',
                fontFamily: undefined
            },
            onDatasetHover: {
                highlightDataSeries: false,
            },
            x: {
                show: true,
                formatter: function (value) {
                    return moment(value).format("HH:mm");
                },
            },
            y: {
                formatter: undefined,
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
            z: {
                formatter: undefined,
                title: 'Size: '
            },
            marker: {
                show: true,
            },
            items: {
                display: "flex",
            },
            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
        }


    }

    const getChartDataForAll = (details, options) => {
        let data = [];
        let cameraList = details?.RouteDetail?.Cam_Details;
        // console.log("LIVE ", cameraList);
        for (let index = 0; index < cameraList?.length; index++) {
            let chart = {};
            let chartOptions = { ...options };
            const element = cameraList[index];
            let name = element.Cam;
            let series = [];
            let xaxisData = [];
            let dataWithDate = {
                dateArray: [],
                data: []
            };

            if (element.LGraph.length > 0) {
                dataWithDate = getDataWithDates(element.LGraph);
            }

            chartOptions['xaxis'] = {
                type: "datetime",
                categories: dataWithDate.dateArray,
            }
            chart['title'] = element.Cam;
            chart['color'] = "primary";
            chart['value'] = element.Cam;
            chart['series'] = [{ name: "files", data: dataWithDate.data }];
            chart['options'] = chartOptions;


            chart['speed'] = getSpeedFromLiveCamaraData(element.Cam);
            data.push(chart);
        }
        return data;
    }

    console.log("Chart Data Live", chartsData);

    useEffect(() => {
        setspeed(true);
        let data = getChartDataForAll(details, options);
        setChartData(data);
    }, [details])




    const macAddress = useSelector(state => state.Active_Store?.macAddress);
    const sid = useSelector(state => state.Login.user.sessionID);
    const [isFetching, setIsFetching] = useState(false);
    const handleOnClickFetchLive = () => {
        console.log(); setspeed(true);
        if (macAddress) {
            let queryParams = {
                sid: sid,
                mac: macAddress,
            }
            dispatch(getLiveCameraData(queryParams, (response) => {
                if (response?.OK) {
                    response?.OK?.data?.map((item) => {
                        chartsData.map((chart) => {
                            if (item.live.bus === chart.title) {
                                chart.speed = item.live?.["Dnld_Speed mbps"];
                            }
                        })
                    });
                }
            }));
        }
        setIsFetching(true);
        setTimeout(() => {
            setIsFetching(false);
        }, 10000);
    
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const hideandshowSpeedlabel = windowWidth > 1199;
    useEffect(() => {
        console.log("locations", location);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window]);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    return (
        <React.Fragment>
            <Card className='mb-2' style={{ height: "97%" }}>
                <CardBody className='pt-2 pb-0'>
                    <CardTitle>
                        <Row>
                            <Col sm={12} md={6}>
                                <h3>Live Status</h3>
                            </Col>
                            <Col sm={12} md={hideandshowSpeedlabel ? 3 : 6} className="d-flex justify-content-center justify-content-sm-start" id="fetch_live">
                                <button className={`btn btn ${!isFetching ? 'btn-primary' : 'btn-secondary'} btn-sm p-2`} disabled={isFetching} onClick={handleOnClickFetchLive}>Fetch Live</button>
                            </Col>
                            {hideandshowSpeedlabel &&
                                <Col sm={12} md={3} className="text-md-center px-0">
                                    <h6>Speed (mbps)</h6>
                                </Col>
                            }
                        </Row>
                    </CardTitle>
                    <br />
                    {(chartsData || []).map((report, key) => (
                        <Col key={key} className="mb-4">
                            <Row>
                                <Col xs={12} xl={4} className='p-0 mt-4 text-center'>
                                    <div>
                                        <h5>{report.value}</h5>
                                    </div>
                                </Col>
                                <Col xs={12} xl={6}>
                                    <div>
                                        <ReactApexChart options={report.options} series={report.series} type="area" height={94} className="apex-charts" />
                                    </div>
                                </Col>
                                <Col xs={12} xl={2} className='p-0 px-0 mt-4 text-center'>
                                    <div>
                                        {!hideandshowSpeedlabel &&
                                            <h6>Speed (mbps)</h6>
                                        }
                                        <h5>{!speedoflivedata ? "N/A" : report.speed}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </CardBody>
            </Card>
        </React.Fragment>
    )

}

export default LiveCamera
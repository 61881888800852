import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, Toast, ToastBody, ToastHeader } from 'reactstrap'
import RouteDetail from './RouteDetail';
import LiveCamera from './LiveCamera';
import RecordingStatus from './RecordingStatus';
import CamerasDetails from './CamerasDetails';
import ShowDataTable from './ShowDataTable';
import Tasks from './Tasks';
import { getCameraActive, getDetailData } from 'store/actions';
import toastr from "toastr";
import Overall from './Overall';

function Detail_Dashboard() {

    document.title = "Detail Dashboard";
    const regex = new RegExp("[()]", "g");
    const dispatch = useDispatch();
    const routeName = useSelector(state => state.Route_List?.activeRoute);
    const routeData = useSelector(state => state.Detail_Dashboard?.data);


    const user = useSelector(state => state.Login.user)
    const group = useSelector(state => state.Active_Store?.sub_group)
    const dates = useSelector(state => state.Active_Store?.dates);
    const sid = user.sessionID;
    const ref = useRef(null);

    const startDate = dates?.startDate;
    const endDate = dates?.endDate;


    const findRouteByName = (array, routeName) => {
        for (let index = 0; index < array?.length; index++) {
            const element = array[index];
            let name = element.RouteDetail.BusRoute;
            name = name.replace(regex, "").trim();
            if (routeName === name) {
                return element;
            }
        }
    }
    const route = findRouteByName(routeData.data?.[0], routeName);
    const activeCamera = route?.RouteDetail?.Cam_Details?.[0]?.Cam;
    const macAddress = route?.RouteDetail?.Cam_Details?.[0]?.mac;

    useEffect(() => {
        let camobj = { activeCamera, macAddress };
        dispatch(getCameraActive(camobj));
        console.log("On load camera", camobj);

    }, [activeCamera])

    useEffect(() => {
        ref.current  =  setInterval(async () => {
            console.log("Interval Calls Main",ref.current);
            const regex = new RegExp("[()]","g");
            let route =  routeName.replace(regex,"").trim().split(" ")[0];
            let queryParams = {
                sid: sid,
                date: startDate,
                enddate: endDate,
                route: route,
                higroup: group,
            }
             dispatch(getDetailData(queryParams));
        }, 180000);
        return () => {
            if(ref.current){
              clearInterval(ref.current)
            }
          }

    }, [startDate,endDate,routeName,group]);
    const snapShotMsg = useSelector(state => state.SnapShot_Detail?.data);
    const snapShotError = useSelector(state =>state.SnapShot_Detail?.apiDataError?.msg);
    const [toast, setToast] = useState(false);
    const toggleToast = () => {
        setToast(!toast);
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const hideOverallComponent = windowWidth < 1400;
  
    useEffect(()=>{
      console.log("locations",location);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      }; 
    },[window]);
    
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    return (
        <div className="page-content mt-3 pb-0 mb-0">
            <React.Fragment>
                <Container fluid>
                    <> 
                    {hideOverallComponent &&
                        <Card>
                            <CardBody>
                              <Overall />  
                            </CardBody>
                        </Card>
                    }
                        <Row>
                            <Col xl={6}>
                                <RouteDetail details={route} />
                            </Col>
                            <Col xl={6}>
                                <CamerasDetails details={route} activeCamera={activeCamera} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={4}>
                                <LiveCamera details={route} />
                            </Col>
                            <Col xl={4}>
                                <Tasks data={routeData?.Tickets} details={route} />
                            </Col>
                            <Col xl={4}>
                                <RecordingStatus details={route} />
                            </Col>
                        </Row>
                        <Row>
                            <ShowDataTable />
                        </Row>
                    </>
                </Container>
            </React.Fragment>
        </div>
    )
}

export default Detail_Dashboard
import {
    GET_ADD_CAM_DATA,
    ADD_CAM_API_SUCCESS,
    ADD_CAM_API_FAIL,
    
} from "./actionTypes";

export const addCamApiSuccess = (data) => ({
    type: ADD_CAM_API_SUCCESS,
    payload: data ,
});

export const addCamApiFail = (actionType, error) => ({
    type: ADD_CAM_API_FAIL,
    payload: { actionType, error },
});


export const getaddCamData = (queryParam,callback) =>({
    type: GET_ADD_CAM_DATA,
    payload: queryParam,
    callback
})


import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { getDetailData, setActiveRoute, setSubGroup } from 'store/actions';

function RouteTab({ route,sub_group }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const regex = new RegExp("[()]","g");

    const user = useSelector(state => state.Login.user);
    const sid = user.sessionID;
    const startDate = useSelector(state => state.Active_Store?.dates?.startDate)
    const endDate = useSelector(state => state.Active_Store?.dates?.endDate);
    // const group = useSelector(state => state.Active_Store?.sub_group);
    const liveCameras = useSelector(state => state.Live_Camera_Data?.data);
    const [flickerLive,setFlickerLive] = useState(false); 
    const group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)
    // console.log(route);


    const setColor = () => {
        if (route.RouteDetail.R_Status == 'A')
            return "#FFBF00"
        if (route.RouteDetail.R_Status == 'Y')
            return "#FFFF00"
        if (route.RouteDetail.R_Status == 'R')
            return "#FF0000"
        if (route.RouteDetail.R_Status == 'G')
            return "#00FF00"
    }

    const setAnimation = () => {
        if (route.RouteDetail.R_Live === '1')
            return "bx-burst"
    }

    const handleOnClick = (routeName) => {
        dispatch(setSubGroup(sub_group));
        dispatch(setActiveRoute(routeName.trim()));
        routeName = routeName.trim();
        let queryParams = {
            sid: sid,
            date: startDate,
            enddate: endDate,
            route: routeName,
            higroup: group,
        }
        dispatch(getDetailData(queryParams,(response) =>{
            // console.log(response);
            if(response?.OK){
                navigate("/detail_dashboard");
            }
        }));
    }


    useEffect(()=>{
        if(liveCameras){
            let liveRoute = liveCameras?.data?.filter(item => item.live.route === route.RouteDetail.Route.split(" ")[0]);
            if(liveRoute?.length > 0){
                setFlickerLive(true)
            }else{
                setFlickerLive(false);
            }
        }else{
            setFlickerLive(false);
        }
    },[liveCameras])

    return (
        <div className='d-flex justify-content-left' onClick={() => { handleOnClick(route.RouteDetail.BusRoute) }} >
            <i className={`bx bxs-circle px-2 pt-1 ${ flickerLive ? 'bx-burst' : ''}`} style={{ color: setColor() }}  ></i>
            <span className='align-center' >{route.RouteDetail.Route.replace(/[()]/g, "")}</span>
        </div>
    )
}

export default RouteTab
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Modal } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "assets/images/login.png";
import logo from "assets/images/logo.svg";

const Login = props => {

  //meta title
  document.title = "Login | Remote Drishtee";

  const dispatch = useDispatch();
  const LoggedInUser = useSelector(state => state.Login.isUserLogout);
  useEffect(() => {
    if (!LoggedInUser) {
      props.router.navigate("/summary_dashboard");
    }
  }, [])
  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      uid: "" || '',
      password: "" || '',
    },
    validationSchema: Yup.object({
      uid: Yup.string().required("Please Enter Your UserName"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setUser(values);
      dispatch(loginUser(values, props.router.navigate, (response) => {
        console.log(response?.error?.msg);
        if (response?.error?.msg === "User Already Logged In") {
          setModal(true);
        }
      }));

    }
  });


  const LoginProperties = createSelector(
    (state) => state.Login,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);
  console.log("Login Page : " + JSON.stringify(error));

  const [modal, setModal] = useState(false);
  const [user, setUser] = useState({});

  const forceLogin = () => {
    let status = 1
    dispatch(loginUser({ status, ...user }, props.router.navigate));

  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={8} xl={8}>
              <Card className="overflow-hidden">
                <Row>
                  <Col sm={6}>
                    <div className="text-left">
                      <Row>
                        <Col className="col-12">
                          <div className="p-4 pb-0">
                            <h4 >Login </h4>
                            <p>Sign in to Your School account.</p>
                          </div>
                        </Col>
                        {/* <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col> */}
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/" className="logo-light-element">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mb-3">
                            {/* <Label className="form-label">UserName</Label> */}
                            <Input
                              name="uid"
                              className="form-control"
                              placeholder="UserName"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.uid || ""}
                              invalid={
                                validation.touched.uid && validation.errors.uid ? true : false
                              }
                            />
                            {validation.touched.uid && validation.errors.uid ? (
                              <FormFeedback type="invalid">{validation.errors.uid}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            {/* <Label className="form-label">Password</Label> */}
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-warning btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center">

                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Col>
                  <Col sm={6} style={{backgroundColor:"rgba(52, 52, 52, 0.8)"}} className="p-0">
                    {/* <CardBody> */}
                      <div className="pt-4">
                        {/* <Col>
                        <Row className="text-center p-4 pb-2">
                         <h2 className="text-warning">Remote Drishtee</h2>
                        </Row>
                        <Row> */}
                          <img src={profile} alt="" style={{width :"100%", height:"268px"}}  className="pt-5 pe-2 ps-n1"/>
                        {/* </Row>
                        </Col>
                             */}
                      </div>
                    {/* </CardBody> */}
                  </Col>
                </Row>
              </Card>
              <div className="mt-5 text-center">

                <p>
                  © {new Date().getFullYear()} RemoteDristee{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={modal} className='modal-md' role="dialog" autoFocus={true} top data-toggle="modal"
        toggle={() => { setModal(!modal); }} >
        <Card>
          <CardBody className="text-center">
            <Row className="pb-2">
              <Alert color="danger">{error}</Alert>
              <h4>Do you want to force login?</h4>
            </Row>
            <Row>
              <Col className="col-sm-auto offset-sm-3">
                <div className="mt-3 d-grid">
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={forceLogin}
                  >
                    Yes
                  </button>
                </div>
              </Col>
              <Col className="col-sm-auto offset-sm-2">
                <div className="mt-3 d-grid">
                  <button
                    className="btn btn-primary btn-block"
                    type="button"
                    onClick={() => { setModal(!modal) }}
                  >
                    No
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

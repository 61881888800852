import { data_table_api } from "helpers/backend_helper";
import { GET_TABLE_DATA, DATA_TABLE_API_FAIL, GET_CHANGING_DATA } from './actionTypes';
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { tableDataApiSuccess, tableDataApiFail, setChangingDataResponse } from "./actions";





function* getDataTableApi({ payload: queryParam, callback }) {
    try {
        var response;
        console.log(" jhdskhaskd saga", queryParam)
        response = yield call(data_table_api, queryParam);
        if (response?.error) {
            console.log("response Data TAable", response);
            yield put(tableDataApiFail(DATA_TABLE_API_FAIL, response.error));
        }else
        {
            if(response?.Warning)
            {
                console.log("response Data TAable", response);
                yield put(tableDataApiFail(DATA_TABLE_API_FAIL, response?.Warning));
            }
            else
            {
                console.log("response Data TAable", response);
                yield put(tableDataApiSuccess(response));
                yield put(tableDataApiFail(DATA_TABLE_API_FAIL, {}));
            }
        }
        callback && callback(response)
        // localStorage.setItem("table_data", response?.OK)
    } catch (error) {
        yield put(tableDataApiFail(DATA_TABLE_API_FAIL, error));
    }
}
function* getChangingDataApi ({payload : queryParam,callback})
{
    try {
        var response ;
        response = yield call (data_table_api,queryParam);
        yield put(setChangingDataResponse(response))
        callback && callback(response)
    } catch (error) {
        yield put(setChangingDataResponse(error))
    }
}

export function* tableApiData() {
    yield takeEvery(GET_TABLE_DATA, getDataTableApi);
    yield takeEvery(GET_CHANGING_DATA,getChangingDataApi);
}


function* dataTableSaga() {
    yield all([fork(tableApiData)]);
}


export default dataTableSaga;

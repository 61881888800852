import {
    GET_SUB_GROUP_DATA,
    SUB_GROUP_API_SUCCESS,
    SUB_GROUP_API_FAIL,
    
} from "./actionTypes";

export const subgroupsApiSuccess = (data) => ({
    type: SUB_GROUP_API_SUCCESS,
    payload: data ,
});

export const subgroupsApiFail = (actionType, error) => ({
    type: SUB_GROUP_API_FAIL,
    payload: { actionType, error },
});


export const getsubgroupsData = (queryParam,callback) =>({
    type: GET_SUB_GROUP_DATA,
    payload: queryParam,
    callback
})


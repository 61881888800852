import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function Rtsp({rtsp}) {
    // console.log(rtsp);
    const [player, setPlayer] = useState();
    const errHandler = (err) => {
        console.error(err.message);
        // alert(err.message);
    };
    useEffect(() => {
        // Initialize the Streamedian player
        try {
            const streamedianPlayer = window.Streamedian.player('live_Stream', {
                bufferDuration: 30,
                socket: "ws://cam.remotedrishtee.com:8080/ws/",
                redirectNativeMediaErrors: true,
                continuousFileLength: 180000,
                eventFileLength: 10000,
                errorHandler: errHandler.bind(),    
            });
            // setPlayer(streamedianPlayer);
            // console.log(streamedianPlayer);
        } catch (error) {
            
            console.log(error);
        }
    }, []);

    return (
        <div >
            <video id="live_Stream" controls autoPlay  style={{ maxWidth: "725px", maxHeight: "550px" }}>
                <source src={rtsp} type="application/x-rtsp" />
            </video>
        </div>
    );
}

export default Rtsp;

import { jwtDecode } from "jwt-decode";
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"


const user = localStorage.getItem('authUser');
const userData = localStorage.getItem('userData');

const initialState = {
  error: "",
  loading: false,
  user: user ? jwtDecode(user) : {},
  userData:userData ? jwtDecode(userData) : {},
  isUserLogout : user ? false :true,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
        user: action.payload.user,
        userData: action.payload.userData,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state , isUserLogout: true}
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false,isUserLogout: false, }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login

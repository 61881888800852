import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Label, Modal, ModalHeader, Row, Table, Tooltip } from 'reactstrap'
import New_Task_Form from './New_Task_form';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import Holiday_Form from './Holiday_Form';
import Task_Detail_Form from './Task_Detail_form';
import { disableCursor } from '@fullcalendar/core/internal';
function Tasks({ data ,details}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
  const [role, setRole] = useState([]);
  useEffect(() => {
    if (permission !== null) {
      setRole(permission.split(",").map(Number));
    }
  }, [])


  useEffect(() => {
    setTasks(data);
  }, [data])
  const [isshowDetailOpen, setIsShowDetailOpen] = useState(false);
  const [taskInfo, setTaskInfo] = useState("");
  const handleTaskDetail = (item) => {
    setIsShowDetailOpen(!isshowDetailOpen);
    setTaskInfo(item);
  }
  const [iscreatetaskOpen, setIsCreateTaskOpen] = useState(false);
  const createTask = () => {
    setIsCreateTaskOpen(!iscreatetaskOpen);
  }
  const toggleTooltip = (index) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  return (
    <>
      <React.Fragment>
        <Card className='mb-2' style={{height : "97%"}} >
          <CardHeader className='bg-transparent text-center pb-0'>
            <Row>
              <Col><h3>Tasks</h3></Col>
              <Col><button className={`btn btn ${!role.includes(30) ? 'btn-secondary' : 'btn-primary'} `} onClick={role.includes(30) ? createTask : null} disabled={!role.includes(30)} >Create</button></Col>
            </Row>
          </CardHeader>
          <hr className='m-2' />
          <CardBody className='pt-0'>
            <div>
              <SimpleBar style={{ maxHeight: "223px",minHeight : "223px" }}>
                <div className="table-responsive">
                  <Table className="table table-wrap align-middle table-hover mb-0">
                    <tbody>
                      {tasks && tasks.map((item, key) => (
                        <tr key={key} onClick={item.type === "0" ? () => handleTaskDetail(item) : () => { }} style={{ cursor: item.type === "0" ? "pointer" : "default" }}>
                          <td width={"125px"} className='p-1'>
                            {moment(item.date).format("DD-MM-YY HH:mm")}
                          </td>
                          <td >
                            {item.type === "1" ? (
                              <span>{item.specs}
                                <a id={`tooltip-${key}`} className="btn btn-link " target='_blank' rel="noreferrer" href={item.remarks} download><i className='fas fa-image font-size-20'></i>  </a>
                                <Tooltip
                                  isOpen={tooltipOpen[`tooltip-${key}`]}
                                  placement="bottom"
                                  target={`tooltip-${key}`}
                                  toggle={() => toggleTooltip(`tooltip-${key}`)}
                                >
                                  Click to Download
                                </Tooltip>
                              </span>
                            ) : item.type === "2" ? (
                              <span>
                                {item.specs}
                                <a id={`tooltip-${key}`} className="btn btn-link font-size-16" role="button" href={item.remarks}>&nbsp;<i className='fas fa-video font-size-17'></i>  </a>
                                <Tooltip
                                  isOpen={tooltipOpen[`tooltip-${key}`]}
                                  placement="bottom"
                                  target={`tooltip-${key}`}
                                  toggle={() => toggleTooltip(`tooltip-${key}`)}
                                >
                                  Click to Download
                                </Tooltip>
                              </span>
                            ) : (
                              <span>
                                {item.specs}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </SimpleBar>
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={isshowDetailOpen} toggle={() => { setIsShowDetailOpen(!isshowDetailOpen) }}>
          <ModalHeader toggle={() => { setIsShowDetailOpen(!isshowDetailOpen) }}>Task Details</ModalHeader>
          <Card>
            <CardBody>
              <Label className="form-label">Date: {taskInfo?.date}</Label><br />
              <Label className="form-label">Remarks: {taskInfo?.remarks}</Label><br />
              <Label className="form-label">Specs : {taskInfo?.specs}</Label>
            </CardBody>
          </Card>
        </Modal>
        <Modal isOpen={iscreatetaskOpen} style={{maxWidth:"800px"}} toggle={() => { setIsCreateTaskOpen(!iscreatetaskOpen) }}>
          <ModalHeader toggle={() => { setIsCreateTaskOpen(!iscreatetaskOpen) }}>Tasks </ModalHeader>
          <Task_Detail_Form details={details}/>
        </Modal>
      </React.Fragment>
    </>
  )
}

export default Tasks
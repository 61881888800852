import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { Alert, Card, Col, Form, FormFeedback, Input, Label, Row, Spinner, Table } from 'reactstrap';
import * as Yup from "yup";
import Add_Modify_cam_detail from './Add_Modify_cam_detail';
import { useSelector } from 'react-redux';
import { getaddFormData, getDetailData } from 'store/actions';
import { useDispatch } from 'react-redux';
import toastr from "toastr";
import axios from 'axios';

const Bus_Detail_Form = ({ Route, NewBus, AddCam, Bus_Nameplate }) => {
    const user = useSelector((state) => state.Login.user);
    const sid = user.sessionID;
    const dates = useSelector(state => state.Active_Store?.dates);
    const group = useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.group)
    const startDate = dates?.startDate;
    const endDate = dates?.endDate;
    const regex = new RegExp("[()]", "g");
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const subGroups = useSelector((state) => state.SubGroups_Detail?.data);
    const [isNewBus, setIsNewBus] = useState(NewBus);
    const [btnAddCam, setbtnAddCam] = useState(false);
    const [updateCam, setUpdateCam] = useState(false);
    const [mac, setMac] = useState(null);
    const [ip, setIp] = useState(null);
    const [btnNewBus, setbtnNewBus] = useState(false);
    const [refreah, setRefresh] = useState(false);
    const [edit, setEdit] = useState(false);
    const [bus_Nameplate, setBus_Nameplate] = useState("");
    const [busOwn, setBusOwn] = useState(null);
    const [busRoute, setBusRoute] = useState(null);
    const [driver_Name, setDriver_Name] = useState(null);
    const [driver_Mobile_No, setDriver_Mobile_No] = useState(null);
    const [cameraList, setCameraList] = useState(null);
    const [details, setDetails] = useState(null);
    const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
    const [role, setRole] = useState([]);
    useEffect(() => {
        if (permission !== null) {
            setRole(permission.split(",").map(Number));
        }
    }, [])

    const drpDown_data = subGroups.reduce((arr, item) => {
        if (item.Hierarchy === "0") {
            const head = item.Group_Head;
            const id = item.Group_ID;

            if (!arr[head]) {
                arr[head] = [];
            }

            arr[head].push(id);
        }
        return arr;
    }, {});
    // const [data, setData] = useState(drpDown_data)
    const routes = Object.values(drpDown_data).flat(1);
    const [drp_Busowner, setDrp_Busowner] = useState(Object.keys(drpDown_data));
    const [drp_Route, setDrp_Route] = useState(routes);
    const [selectedBusOwner, setSelectedBusOwner] = useState('');
    const [selectedRoute, setSelectedRoute] = useState('');

    useEffect(() => {
        if (Route === null) {
            setIsLoading(false);
        }
        if (Route !== null) {
            detail_api_call(Route);
        }
    }, []);
    const detail_api_call = (route) => {
        let queryParams = {
            sid: sid,
            date: startDate,
            enddate: endDate,
            route: route,
            higroup: group,
        }
        axios.get('https://remotedrishtee.com/api/apiv2/apis/portal_IPC1_mainmenu.php', { params: queryParams })
            .then(function (response) {
                let data = findRouteByName(response?.data.OK.data?.[0], route)
                if (response?.data.OK) {
                    setIsLoading(false);
                }
                setDetails(data?.RouteDetail);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    useEffect(() => {
        if (details !== null && details !== undefined) {
            subGroups.map((item) => {
                if (item.Hierarchy === '0') {
                    if (item.Group_ID === (Route)) {
                        setBusOwn(item.Group_Head);
                        setSelectedBusOwner(item.Group_Head);
                    }
                }
            });
            setBus_Nameplate(details.Plate)
            setBusRoute(details.BusRoute);
            setDriver_Name(details.Driver);
            setDriver_Mobile_No(details.DrMobile);
            setCameraList(details.Cam_Details);
            setSelectedRoute(details.BusRoute);   
        }
    }, [details])
    const findRouteByName = (array, routeName) => {
        for (let index = 0; index < array?.length; index++) {
            const element = array[index];
            let name = element.RouteDetail.BusRoute;
            name = name.replace(regex, "").trim();
            if (routeName === name) {
                return element;
            }
        }
    }
    toastr.options = {
        positionClass: "toast-top-center",
        progressBar: "progressBar",
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "slideDown",
        hideMethod: "slideUp",
        timeOut: 2000,
    };
    const busValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Bus_Nameplate: isNewBus ? Bus_Nameplate : bus_Nameplate || '',
            Driver_Name: refreah ? '' : driver_Name || '',
            Route_No: isNewBus ? selectedRoute : busRoute || '',
            Driver_Mobile_No: refreah ? '' : driver_Mobile_No || '',
            Bus_Owner: isNewBus  ? selectedBusOwner : busOwn || '',
        },
        validationSchema: Yup.object({
            Bus_Nameplate: Yup.string().required('Please Enter Bus Nameplate').matches(/.*\d{4}$/, 'Please Provide Valid Bus Nameplate'),
            Driver_Name: Yup.string().required('Please Enter Driver Name'),
            Route_No: Yup.string().required('please select Route No'),
            Driver_Mobile_No: Yup.string()
                .matches(/[6789]\d{9}$/, 'Please Provide Valid Mobile Number')
                .required('Please Enter Driver Mobile No'),
            Bus_Owner: Yup.string().required('please select Bus Owner'),
        }),
        onSubmit: (values) => {
            let queryParam = {
                sid: sid,
                id: 0,
                p1: isNewBus ? Bus_Nameplate : values.Bus_Nameplate,
                p2: selectedRoute,
                p3: selectedBusOwner,
                p4: values.Driver_Mobile_No+values.Driver_Name,
                p5: !edit ? "11111" : `${values.Bus_Nameplate !== bus_Nameplate ? 1 : 0}${selectedRoute !== busRoute  ? 1 : 0}${selectedBusOwner !== busOwn         
                     ? 1 : 0}${values.Driver_Name !== driver_Name ? 1 : 0}${values.Driver_Mobile_No !== driver_Mobile_No ? 1 : 0}`,
            };
            dispatch(getaddFormData(queryParam, (response) => {
                if (response?.OK) {
                    setDetails(detail_api_call(busValidation.values.Route_No));
                    toastr.success(response?.OK.msg);
                    if(!NewBus)
                    {
                        let queryParams = {
                            sid: sid,
                            date: startDate,
                            enddate: endDate,
                            route: selectedRoute,
                            higroup: group,
                        }
                         dispatch(getDetailData(queryParams));
                    }
                    if (NewBus) {
                        setIsNewBus(!isNewBus)
                    }
                }
                if (response?.error) {
                    toastr.error(response?.error.msg);
                }
            }));
        },

    });


    const handleOnChangeRoute = (e) => {
        setSelectedRoute(e.target.value);
        busValidation.handleChange;
    };

    const handleOnChangeOwner = (e) => {
        setSelectedBusOwner(e.target.value);
        setDrp_Route(drpDown_data[e.target.value] || []);
        setSelectedRoute('');
        busValidation.handleChange;
    };
    useEffect(() => {
        if (selectedRoute) {
            for (const [busowner, routellist] of Object.entries(drpDown_data)) {
                if (routellist.includes(selectedRoute)) {
                    setSelectedBusOwner(busowner);
                    break;
                }
            }
        }
    }, [selectedRoute, drpDown_data]);


    const handleAddCamera = () => {
        setbtnAddCam(true);
        setUpdateCam(false);
        setMac(null);
    };

    const handleUpdateCamera = (mac, ip) => {
        setUpdateCam(true);
        setbtnAddCam(false);
        setMac(mac);
        setIp(ip)
    };

    const handleNewBus = () => {
        setbtnNewBus(true);
        setbtnAddCam(true);
        setMac(null);
        setIp(null);
    };

    const handleRefresh = () => {
        setSelectedBusOwner('');
        setSelectedRoute('');
        setRefresh(true);
        setEdit(true);
    };

    const handleEdit = () => {
        setEdit(true);
    };
    return (
        <Card style={{ paddingBottom: isLoading ? "15px" : "0px" }}>
            {
                !updateCam && !btnAddCam ?
                    isLoading ?
                        <div>
                            <Spinner color="primary" className="position-absolute start-50" />
                        </div>
                        :
                        <Form onSubmit={busValidation.handleSubmit}>
                            <Row className='p-3 pt-2'>
                                <Col className='col-12'>
                                    {!isNewBus || !NewBus ?
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col className='text-center'>
                                                        <button
                                                            className={`btn btn ${!role.includes(32) ? 'btn-secondary' : 'btn-primary'} `}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleEdit();
                                                            }}
                                                            disabled={!role.includes(32)} >
                                                            Edit
                                                        </button>
                                                    </Col>
                                                    <Col className='text-center'>
                                                        <button
                                                            className='btn btn-primary'
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleNewBus();
                                                            }}
                                                            disabled={isNewBus}
                                                        >
                                                            New Bus
                                                        </button>
                                                    </Col>
                                                    <Col className='text-center'>
                                                        <button
                                                            className={`btn btn ${!role.includes(32) ? 'btn-secondary' : 'btn-primary'} `}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleRefresh();
                                                            }}
                                                            disabled={!role.includes(32)} >
                                                            Refresh
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        : <></>
                                    }
                                    <Row>
                                        <div>
                                            <Row >
                                                <Col className='col-6'>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Bus Nameplate</Label>
                                                        <Input
                                                            name="Bus_Nameplate"
                                                            className="form-control"
                                                            placeholder="Enter Bus Nameplate"
                                                            type="text"
                                                            onChange={busValidation.handleChange}
                                                            onBlur={busValidation.handleBlur}
                                                            value={busValidation.values.Bus_Nameplate || ""}
                                                            invalid={
                                                                busValidation.touched.Bus_Nameplate && busValidation.errors.Bus_Nameplate ? true : false
                                                            }
                                                            disabled
                                                            autoComplete="off" />
                                                        {busValidation.touched.Bus_Nameplate && busValidation.errors.Bus_Nameplate ? (
                                                            <Alert color="danger">{busValidation.errors.Bus_Nameplate}</Alert>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col className='col-6'>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Bus Owner</Label>
                                                        <select className="form-select" name="Bus_Owner"
                                                            onChange={(e) => handleOnChangeOwner(e)}
                                                            onBlur={busValidation.handleBlur}
                                                            value={isNewBus || edit ? selectedBusOwner : busValidation.values.Bus_Owner || ""} disabled={!edit && !isNewBus}>
                                                            <option value="" disabled hidden>Select Bus Owner...</option>
                                                            {
                                                                drp_Busowner.map((owner, index) => (
                                                                    <option value={owner} key={index}>{owner}</option>
                                                                ))
                                                            }

                                                        </select>
                                                        <div>
                                                            {busValidation.touched.Bus_Owner && busValidation.errors.Bus_Owner ? (
                                                                <Alert color="danger">{busValidation.errors.Bus_Owner}</Alert>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-6'>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Route No</Label>
                                                        <select className="form-select" name="Route_No"
                                                            onChange={(e) => handleOnChangeRoute(e)}
                                                            onBlur={busValidation.handleBlur}
                                                            value={isNewBus || edit ? selectedRoute : busValidation.values.Route_No || ""} disabled={!edit && !isNewBus}>
                                                            <option value="" disabled hidden>Select Route...</option>
                                                            {
                                                                drp_Route.map((route, index) => (
                                                                    <option key={index} value={route}>{route}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {busValidation.touched.Route_No && busValidation.errors.Route_No ? (
                                                            <Alert color="danger">{busValidation.errors.Route_No}</Alert>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col className='col-6'>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Driver Name</Label>
                                                        <Input
                                                            name="Driver_Name"
                                                            className="form-control"
                                                            placeholder="Enter Driver Name"
                                                            type="text"
                                                            onChange={busValidation.handleChange}
                                                            onBlur={busValidation.handleBlur}
                                                            value={busValidation.values.Driver_Name || ""}
                                                            invalid={
                                                                busValidation.touched.Driver_Name && busValidation.errors.Driver_Name ? true : false
                                                            } disabled={!edit && !isNewBus}
                                                            autoComplete="off" />
                                                        {busValidation.touched.Driver_Name && busValidation.errors.Driver_Name ? (
                                                            <Alert color="danger">{busValidation.errors.Driver_Name}</Alert>
                                                        ) : null}
                                                    </div>
                                                </Col>  
                                                <Col className='col-6'>
                                                    <div className="mb-3">
                                                        <Label className="form-label">Driver Mobile No</Label>
                                                        <Input
                                                            name="Driver_Mobile_No"
                                                            className="form-control"
                                                            placeholder="Enter Driver Mobile No"
                                                            type="text"
                                                            onChange={busValidation.handleChange}
                                                            onBlur={busValidation.handleBlur}
                                                            value={busValidation.values.Driver_Mobile_No || ""}
                                                            invalid={
                                                                busValidation.touched.Driver_Mobile_No && busValidation.errors.Driver_Mobile_No ? true : false
                                                            } disabled={!edit && !isNewBus}
                                                            autoComplete="off" />
                                                        {busValidation.touched.Driver_Mobile_No && busValidation.errors.Driver_Mobile_No ? (
                                                            <Alert color="danger">{busValidation.errors.Driver_Mobile_No}</Alert>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                    <Row>
                                        {
                                            !isNewBus || !NewBus ?
                                                <Row>
                                                    <Card>
                                                        <Label className="form-label">Associated Devices</Label>
                                                        <div className="table-responsive">
                                                            <Table className="table mb-0">
                                                                <thead className="table-light">
                                                                    <tr key="thead">
                                                                        <th> Device</th>
                                                                        <th>Position</th>
                                                                        <th>IP Address</th>
                                                                        <th>MAC ID</th>
                                                                        <th><button type='button' color="primary" className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); handleAddCamera(); }} disabled={btnAddCam}>Add Cam</button></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        cameraList && cameraList.map(item => (
                                                                            <tr key={item.mac}>
                                                                                <th scope="row">Camera</th>
                                                                                <td>{item.Cam}</td>
                                                                                <td>{item.ip}</td>
                                                                                <td>{item.mac}</td>
                                                                                {role.includes(31) ?
                                                                                    <td><button type='button' color="primary" className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); handleUpdateCamera(item.mac, item.ip); }}>Update</button></td>
                                                                                    : <></>
                                                                                }
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </Card>
                                                </Row>
                                                : <></>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            {!btnAddCam && !updateCam ? (
                                <Row>
                                    <Col className='text-center pb-3'>
                                        <button type='submit' name='busValidation' className={`btn btn ${!edit && !isNewBus ? 'btn-secondary' : 'btn-primary'} `} disabled={!edit && !isNewBus} onSubmit={busValidation.handleSubmit}>
                                            Submit Bus
                                        </button>
                                    </Col>
                                </Row>
                            ) : <></>}
                        </Form>

                    :
                    <Add_Modify_cam_detail
                        Route={busRoute}
                        Bus_Nameplate={bus_Nameplate}
                        NewBus={btnNewBus}
                        AddCam={btnAddCam}
                        Mac={mac}
                        Ip={ip}
                    />
            }
        </Card>
    );
};

export default Bus_Detail_Form;

import { color } from "echarts";
import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Table, Tooltip } from "reactstrap";
import { getChangingData, getTableData, setSelectedFiles } from "store/actions";
import toastr from "toastr";


function ShowDataTable() {
  const macAddress = useSelector(state => state.Active_Store?.macAddress);
  const [selectedAction, setSelectedAction] = useState(0);
  const [showDataInTable, setShowDataInTable] = useState([]);
  const user = useSelector(state => state.Login.user);
  const sid = user.sessionID;
  const startDate = useSelector(state => state.Active_Store?.dates?.startDate)
  const endDate = useSelector(state => state.Active_Store?.dates?.endDate);
  const dispatch = useDispatch();
  const [tooltipOpen, setTooltipOpen] = useState({}); // State object for tooltip visibility
  const noDataError = useSelector(state => state.Data_Table?.apiDataError?.error?.msg);
  const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
  const [role, setRole] = useState([]);
  const tableData = useSelector(state => state.Data_Table?.data)
  const [ismodelOpen, setIsModelOpen] = useState(false);
  const [message, setMessage] = useState("");
  const options = [

    { id: "0", value: "Show All Files" },
    { id: "1", value: "Show Downloaded Files" },
    { id: "2", value: "Show Pending Files" },
    { id: "3", value: "Show Error Files" },
    { id: "4", value: "Show Deleted Files" },
  ]
  useEffect(() => {
    if (permission !== null) {
      setRole(permission.split(",").map(Number));
    }
  }, [])

  function compare(a, b) {
    let dateA = moment(a.FileDetails.FileDate);
    let dateB = moment(b.FileDetails.FileDate);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }




  const getDataToShowInTable = (sortedData) => {

    const result = [];

    sortedData.forEach(item => {
      const fileDate = moment(item.FileDetails.FileDate);
      const dayMonthYear = fileDate.format('DD-MMM-YYYY');
      const hour = fileDate.format('H');

      const existingDateObject = result.find(obj => Object.keys(obj)[0] === dayMonthYear);

      if (existingDateObject) {
        const existingHourObject = existingDateObject[dayMonthYear].find(obj => Object.keys(obj)[0] === hour);

        if (existingHourObject) {
          existingHourObject[hour].push({
            min: fileDate.format('mm'),
            status: item.FileDetails.Status,
            file: item.FileDetails.Hyperlink,
            filename: item.FileDetails.File,
            fileDate: item.FileDetails.FileDate,
          });
        } else {
          existingDateObject[dayMonthYear].push({
            [hour]: [{
              min: fileDate.format('mm'),
              status: item.FileDetails.Status,
              file: item.FileDetails.Hyperlink,
              filename: item.FileDetails.File,
              fileDate: item.FileDetails.FileDate,
            }]
          });
        }
      } else {
        result.push({
          [dayMonthYear]: [{
            [hour]: [{
              min: fileDate.format('mm'),
              status: item.FileDetails.Status,
              file: item.FileDetails.Hyperlink,
              filename: item.FileDetails.File,
              fileDate: item.FileDetails.FileDate,
            }]
          }]
        });
      }
    });

    console.log(result);
    return result;
  }



  const onChangeAction = (value) => {
    console.log("value selected:", value);
    setSelectedAction(value);
  }
  useEffect(() => {
    let noData = [];
    setSelectedAction("0");
    setShowDataInTable(noData);
    console.log(showDataInTable);
    let queryParam = {
      "sid": sid,
      "date": startDate,
      "camid": macAddress,
      "rid": 0,
      "enddate": endDate,
      "payload": ""
    }

    if (macAddress) {
      dispatch(getTableData(queryParam, (response) => {

        if (response.OK) {

          const sortedData = response.OK.data.sort(compare);
          const showDataInTable = getDataToShowInTable(sortedData);
          setShowDataInTable(showDataInTable);
          console.log("Show Data ", showDataInTable);

        } else {
          setShowDataInTable(noData);
        }
        console.log("Show Data ", showDataInTable);
        console.log("Api response", response);
      }))
    } else {
      console.log("No mac Address");
    }
    setSelectedValues([])
  }, [macAddress, sid, startDate, endDate])


  const setColor = (status) => {
    if (status == 'O')
      return "orange"
    if (status == 'E')
      return "red"
    if (status == 'R')
      return "green"
    if (status == 'D')
      return "skyblue"
  }
  const toggleTooltip = (filename) => {
    setTooltipOpen(prevState => ({
      ...prevState,
      [filename]: !prevState[filename] // Toggle visibility of tooltip associated with filename
    }));
  };
  const [selectedValues, setSelectedValues] = useState([]);
  const addToPayload = (event) => {
    const { value, checked } = event.target;
    const parsedValue = JSON.parse(value);

    if (checked) {
      setSelectedValues([...selectedValues, parsedValue]);
    } else {
      setSelectedValues(selectedValues.filter(val => val.filename !== parsedValue.filename));
      console.log(selectedValues.filter(val => val.filename !== parsedValue.filename));
    }
  };

  function compareSelectValues(a, b) {
    let dateA = moment(a.fileDate);
    let dateB = moment(b.fileDate);
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }
  const getAllFilesBetweendates = () => {
    let sortedValue = selectedValues.sort(compareSelectValues);
    let stDate = moment(sortedValue[0].fileDate).format('YYYY-MM-DD HH:mm:ss');
    let enDate = moment(sortedValue[sortedValue.length - 1].fileDate).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
    console.log("startDate", stDate);
    console.log("end Date", enDate);
    console.log(sortedValue);
    let files = [];
    let count = 0;
    tableData.map((item) => {
      let currentDate = moment(item?.FileDetails?.FileDate).format('YYYY-MM-DD HH:mm:ss');
      if (moment(currentDate).isBefore(enDate) && moment(currentDate).isAfter(stDate) || (moment(currentDate).isSame(stDate) || moment(currentDate).isSame(enDate))
      ) {
        if (selectedAction === "6") {
          if (item?.FileDetails?.Status === "O") {
            files.push(item?.FileDetails?.File);
          }
        }
        if (selectedAction === "8") {
          if (item?.FileDetails?.Status === "D") {
            files.push(item?.FileDetails?.File);
          }
        }
      }
    })
    console.log("files", files);
    setIsModelOpen(!ismodelOpen);
    if (files.length > 0) {
      let queryParam = {
        "sid": sid,
        "date": moment(stDate).format('YYYY-MM-DD'),
        "camid": macAddress,
        "rid": selectedAction,
        "enddate": moment(enDate).format('YYYY-MM-DD'),
        "payload": "",
      }
      dispatch(getChangingData(queryParam, (response) => {
        if (response?.OK) {
          setTimeout(() => {
            setSelectedAction("0");
            resetCheckBox();
            let queryParam = {
              "sid": sid,
              "date": startDate,
              "camid": macAddress,
              "rid": 0,
              "enddate": endDate,
              "payload": "",
            }
            dispatch(getTableData(queryParam, (response) => {
              if (response?.OK) {
                console.log("queryParam")
                if (response?.OK?.msg !== "Data found") {
                  setIsModelOpen(!ismodelOpen);
                  toastr.success(response?.OK?.msg);
                  console.log(response?.OK?.msg)
                }
                const sortedData = response.OK.data.sort(compare);
                const showDataInTable = getDataToShowInTable(sortedData);
                setShowDataInTable(showDataInTable);
              } else {
                if (response?.error) {
                  toastr.error(response?.error?.msg);
                }
                if (response?.Warning) {
                  toastr.warning(response?.Warning?.msg);
                }
                setShowDataInTable(noData);
              }
              console.log("Show Data ", showDataInTable);
              console.log("Api response", response);
            }))
            setSelectedAction("0");
            toastr.success("Request Completed ")
          }, 1000);
        }
        else {
          if (response?.error) {
            toastr.error(response?.error?.msg);
            resetCheckBox();
          }
        }
      }));
    }
  }

  const handleToPayload = () => {
    toastr.options = {
      positionClass: "toast-top-center",
      progressBar: "progressBar",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "slideDown",
      hideMethod: "slideUp",
      timeOut: 3000,
    };
    if (macAddress && selectedAction === "10") {
      if (selectedValues.length > 0) {
        let files = selectedValues.filter(item => item.status === "D")
        if (files.length > 0) {
          toastr.warning(" Deleted Files cannot be Download !");
          resetCheckBox();
        }
        else {
          console.log(selectedValues);
          selectedValues.map((item) => {
            let queryParam = {
              "sid": sid,
              "date": "",
              "camid": macAddress,
              "rid": 10,
              "enddate": "",
              "payload": item.filename,
            }
            dispatch(getChangingData(queryParam));
          });
          toastr.success("Chosen files requested for download !!!")
          resetCheckBox();
        }
      }
      else {
        toastr.warning(" Select File From Table !");
        resetCheckBox();
      }
    }
    if (macAddress && selectedAction === "6" || selectedAction === "8") {
      if (selectedValues.length > 0) {
        let sortedValue = selectedValues.sort(compareSelectValues);
        let sDate = moment(sortedValue[0].fileDate).format('DD-MMM-YYYY');
        let lastDate = moment(sortedValue[sortedValue.length - 1].fileDate).format('DD-MMM-YYYY');
        if (selectedAction === "6") {
          let selectedfiles = selectedValues.filter(item => item.status !== "O")
          if (selectedfiles.length > 0) {
            toastr.warning(" Select Only Pending Files !");
            resetCheckBox();
          }
          else {
            if (moment(sDate).format('DD-MMM-YYYY') === moment(lastDate).format('DD-MMM-YYYY')) {
              setMessage(`Could you please confirm if you'd like to proceed with changing all pending files to deleted files for ${sDate} ?`);
            }
            else {
              console.log(sortedValue);
              console.log(sDate);
              console.log(lastDate);
              setMessage(`Could you please confirm if you'd like to proceed with changing all pending files to deleted files for ${sDate} to ${lastDate} ?`);
            }
            setIsModelOpen(!ismodelOpen);
          }
        }
        if (selectedAction === "8") {
          let selectedfiles = selectedValues.filter(item => item.status !== "D")
          if (selectedfiles.length > 0) {
            toastr.warning(" Select Only Deleted Files !");
            resetCheckBox();
          }
          else {
            if (moment(sDate).format('YYYY-MM-DD') === moment(lastDate).format('YYYY-MM-DD')) {
              setMessage(`Could you please confirm if you'd like to proceed with changing all deleted files to pending files for ${sDate} ?`);
            }
            else {
              setMessage(`Could you please confirm if you'd like to proceed with changing all deleted files to pending files for ${sDate} to ${lastDate} ?`);
            }
            setIsModelOpen(!ismodelOpen);
          }
        }

      }
      else {
        toastr.warning(" Select File From Table !");
        resetCheckBox();
      }
    }
    if (macAddress && selectedAction !== "6" && selectedAction !== "8" && selectedAction !== "10") {
      let noData = [];
      setShowDataInTable(noData);
      resetCheckBox();
      let queryParam = {
        "sid": sid,
        "date": startDate,
        "camid": macAddress,
        "rid": selectedAction,
        "enddate": endDate,
        "payload": "",
      }
      console.log("queryParam")
      dispatch(getTableData(queryParam, (response) => {
        if (response?.OK) {
          console.log("queryParam")
          toastr.success("Request Completed");
          if (response?.OK?.msg !== "Data found") {
            toastr.success(response?.OK?.msg);
            console.log(response?.OK?.msg)
          }
          const sortedData = response.OK.data.sort(compare);
          const showDataInTable = getDataToShowInTable(sortedData);
          setShowDataInTable(showDataInTable);
        } else {
          if (response?.error) {
            toastr.error(response?.error?.msg);
          }
          if (response?.Warning) {
            toastr.warning(response?.Warning?.msg);
          }
          setShowDataInTable(noData);
        }
        console.log("Show Data ", showDataInTable);
        console.log("Api response", response);
      }))
    } else {
      console.log("No mac Address");
    }
  };
  useEffect(() => {
    let files = [];
    selectedValues.map((item) => {
      files.push(item.filename);
    })
    console.log(files);
    dispatch(setSelectedFiles(files));
  }, [selectedValues]);

  const resetCheckBox = () => {
    selectedValues.map((val) => {
      let checkbox = document.getElementById(val.filename);
      if (checkbox) {
        checkbox.checked = false;
      }
    })
    setSelectedValues([]);
  }
  return (
    <React.Fragment>
      <Card className="m-0 mt-2">
        <CardBody className="p-0 mt-0 ">
          <>
            <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <label className="input-group-text">Action</label>
                  <select className="form-select form-select-xl" value={selectedAction}
                    onChange={(e) => { onChangeAction(e.target.value); }}>
                    {
                      options.map((item, id) => (
                        <option key={item.id} value={item.id}>{item.value}</option>
                      ))
                    }
                    {role.includes(35) && <option value="10">Download From Camera/Server</option>}
                    {role.includes(26) && <option value="6">Change pending files to deleted files</option>}
                    {role.includes(27) && <option value="8">Change deleted files to pending files</option>}
                  </select> &nbsp;&nbsp;&nbsp;
                  <button className="btn btn-sm btn-primary" onClick={handleToPayload}>Submit</button>  &emsp;
                  <button className="btn btn-sm btn-info" onClick={resetCheckBox}>Reset</button>
                </div>

              </div>
            </div>
            {noDataError && <div>{noDataError}</div>}

            {showDataInTable.length > 0 &&
              <Table bordered className="border-black" responsive style={{ textAlign: "center" }}>
                <tbody>
                  <tr>
                    <td className="p-0" style={{ backgroundColor: "#545a6d", color: "white" }}>Date</td>
                    {
                      showDataInTable && showDataInTable.map((data, key) => {
                        for (let date in data) {
                          let colspan = 0;
                          let h = data[date];
                          h.map((v) => {
                            colspan += Object.values(v)[0]?.length;
                          })
                          return (<td key={key} colSpan={colspan} className="p-0 " style={{ backgroundColor: "#545a6d", color: "white", fontWeight: "bold" }}>{date}</td>)
                        }
                      })
                    }
                  </tr>
                  <tr >
                    <td className="p-0">Hour</td>
                    {
                      showDataInTable &&
                      showDataInTable.map(dateObj => {
                        for (const date in dateObj) {
                          const entries = dateObj[date];
                          return entries.map(hourObj => {
                            for (const hour in hourObj) {
                              const minutesArray = hourObj[hour];
                              return (
                                <td key={`${date}-${hour}`} colSpan={minutesArray.length} className="p-0" > {hour}  </td>
                              );
                            }
                            return null;
                          });
                        }
                        return null;
                      })

                    }
                  </tr>
                  <tr>
                    <td className="p-1">Mintes</td>
                    {showDataInTable.map(dateObj => {
                      for (const date in dateObj) {
                        const entries = dateObj[date];
                        return entries.map(hourObj => {
                          for (const hour in hourObj) {
                            const minutesArray = hourObj[hour];
                            return (
                              minutesArray.map((details, index) => {
                                let key = `${date}-${hour}-${details.min}-${index}`;
                                return (
                                  <td key={key} className="p-1" >
                                    {details.min}
                                  </td>
                                )
                              })
                            );
                          }
                          return null;
                        });
                      }
                      return null;
                    })}
                  </tr>
                  <tr >
                    <td className="p-0" >Recording</td>

                    {showDataInTable.map(dateObj => {
                      for (const date in dateObj) {
                        const entries = dateObj[date];
                        return entries.map(hourObj => {
                          for (const hour in hourObj) {
                            const minutesArray = hourObj[hour];
                            return (

                              minutesArray.map((details, index) => {
                                let key = `${date}-${hour}-${details.min}-${index}`;
                                return (
                                  <td key={key} style={{ backgroundColor: setColor(details.status), color: "white" }} className="p-0" >
                                    {details.status}
                                  </td>
                                )
                              }
                              ));
                          }
                          return null;
                        });
                      }
                      return null;
                    })}
                  </tr>
                  <tr>
                    <td className="p-0">Files</td>
                    {showDataInTable.map(dateObj => {
                      for (const date in dateObj) {
                        const entries = dateObj[date];
                        return entries.map(hourObj => {
                          for (const hour in hourObj) {
                            const minutesArray = hourObj[hour];
                            return (

                              minutesArray.map((details, index) => {
                                let key = `${date}-${hour}-${details.min}-${index}`;
                                return (
                                  <td key={key} id={details.filename.replace(/[^\w-]/g, '')} className="p-0" >
                                    <input type="checkbox" id={details.filename} value={JSON.stringify(details)} onChange={(e) => { addToPayload(e) }} />
                                    <Tooltip isOpen={tooltipOpen[details.filename]} placement="bottom" target={details.filename.replace(/[^\w-]/g, '')} toggle={() => toggleTooltip(details.filename)}>
                                      {details.filename} </Tooltip>
                                  </td>
                                )
                              })
                            );
                          }
                          return null;
                        });
                      }
                      return null;
                    })}
                  </tr>
                </tbody>
              </Table>
            }
          </>
        </CardBody>
      </Card>

      <Modal style={{ maxWidth: "450px" }} isOpen={ismodelOpen} toggle={() => { setIsModelOpen(!ismodelOpen) }}>
        <ModalBody className="text-center ">
          <Row className="pb-2">
            <h5>{message}</h5>
          </Row>
          <Row>
            <Col className="col-sm-auto offset-sm-3">
              <div className="mt-3 d-grid">
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  onClick={getAllFilesBetweendates}
                >
                  Yes
                </button>
              </div>
            </Col>
            <Col className="col-sm-auto offset-sm-2">
              <div className="mt-3 d-grid">
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  onClick={() => { setIsModelOpen(!ismodelOpen); resetCheckBox(); }}
                >
                  No
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ShowDataTable
import React from "react";
import { Col, Row } from "reactstrap";
import ApexRadial from "./ApexRadial"
import { useSelector } from "react-redux";

const Overall = () => {
  const detail_store = useSelector(state => state.Detail_Dashboard?.data)
  console.log(detail_store)
  const completion = detail_store?.Completion;
  const Pending = detail_store?.Playlist;
  const Today = detail_store?.TodayRecords;
  const Downloaded = detail_store?.Records;

  return (
    <>
      <Row style={{ height: "102px",overflowY:"auto"}}>
        <Col sm={8}>
          <p className="text-center pt-3"> <h5><b>Overall</b></h5></p>
          <Row className="text-center">
            <Col> <p className=" text-muted text-center pb-0"><h6>{Pending}<br />Pending</h6></p></Col>
            <Col><p className=" text-muted text-center"><h6>{Today}<br />Today</h6></p></Col>
            <Col><p className=" text-muted text-center"><h6>{Downloaded}<br />Downloaded</h6></p></Col>
          </Row>
        </Col>
        <Col sm={4}>
          <ApexRadial dataColors='["--bs-primary"]' percentcompletion={completion !== undefined ? completion : 0} />
        </Col>
      </Row>
    </>
  );

}
export default Overall;
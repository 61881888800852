import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Card, CardBody, CardTitle, Progress, CardHeader } from "reactstrap";

const GpsStatus = ({ GPS }) => {
    console.log(GPS);
    const TotalBusCount = GPS?.BusGPS?.TotalBusCount;
    const Moving = GPS?.BusGPS?.Moving;
    const Overspeed = GPS?.BusGPS?.Overspeed;
    const AtSchoolPark = GPS?.BusGPS?.AtSchoolPark;
    const Over1Day = GPS?.BusGPS?.Over1Day;
    const BusOnline = GPS?.BusGPS?.BusOnline;
    const Stationed = GPS?.BusGPS?.Stationed;
    const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
    const [role, setRole] = useState([]);
    useEffect(() => {
        if (permission !== null) {
            setRole(permission.split(",").map(Number));
        }
    }, [])

    return (

        <React.Fragment>
            <Card className="summary_card">
                <CardHeader><h4>GPS Status</h4></CardHeader>
                <CardBody>
                    <Row className="pt-2">
                        <Row>
                            <Col>
                                <p>
                                    <h5>{TotalBusCount}</h5>
                                    <span className="text-muted">Total Fleet</span>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <h5 className="text-success">{Moving}</h5>
                                    <span className="text-muted">Moving</span>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <h5 className="text-danger">{Overspeed}</h5>
                                    <span className="text-muted">OverSpeed</span>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    <h5 className="text-warning">{Stationed}</h5>
                                    <span className="text-muted">Stationary</span>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <h5>{AtSchoolPark}</h5>
                                    <span className="text-muted">School Parking</span>
                                </p>
                            </Col>
                            <Col>
                                <p>
                                    <h5>{Over1Day}</h5>
                                    <span className="text-muted">1 day</span>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <div className="mb-4">
                                <Progress multi>
                                    <Progress bar color="danger" value={Overspeed}></Progress>
                                    <Progress bar color="success" value={Moving}></Progress>
                                    <Progress bar color="warning" value={Stationed}></Progress>
                                </Progress>
                            </div>
                        </Row>
                        <Row>
                            <Col><p className="text-muted"><i className="mdi mdi-circle text-danger me-1" />OverSpeed</p></Col>
                            <Col><p className="text-muted"><i className="mdi mdi-circle text-success me-1" />Moving</p></Col>
                            <Col><p className="text-muted"><i className="mdi mdi-circle text-warning me-1" />Stationary</p></Col>
                        </Row>
                        {role.includes(33) &&
                            <div className='text-end pt-5'>
                                <a href="https://track.remotedrishtee.com/?token=SDBGAiEAuDvdgBIKEjkg_wxUxNA6QUAsHLGgi4ti0KHizRYj5PACIQDpNuv60w4msx47smNKRTlY6WPMCEETgMjIcgYai-KPgnsidSI6MTUsImUiOiIyMDI0LTA1LTEzVDE4OjMwOjAwLjAwMCswMDowMCJ9" target='_blank' rel="noreferrer" className="link" >For More Details</a>
                            </div>
                        }
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default GpsStatus;
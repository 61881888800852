import {   
    SEARCH_MAC_API_SUCCESS,
    SEARCH_MAC_API_FAIL
} from "./actionTypes";

const INIT_STATE = {
    data: {},
};

const SearchMac_ID = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEARCH_MAC_API_SUCCESS:
            return {
                ...state,
                data: action.payload.OK
            };

        case SEARCH_MAC_API_FAIL:
            return {
                ...state,
                data: action.payload.error
            };

        default:
            return state;
    }
}

export default SearchMac_ID;
import {
    GET_MAC_ID,
    SEARCH_MAC_API_SUCCESS,
    SEARCH_MAC_API_FAIL,
    
} from "./actionTypes";

export const searchMacApiSuccess = (data) => ({
    type: SEARCH_MAC_API_SUCCESS,
    payload: data ,
});

export const searchMacApiFail = (actionType, error) => ({
    type: SEARCH_MAC_API_FAIL,
    payload: { actionType, error },
});


export const getmacId = (queryParam,callback) =>({
    type: GET_MAC_ID,
    payload: queryParam,
    callback
})


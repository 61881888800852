import {
    GET_DEBUG_SCREEN_DATA,
    DEBUG_SCREEN_API_SUCCESS,
    DEBUG_SCREEN_API_FAIL,
    SET_LOADING
} from "./actionTypes";

export const debug_screenApiSuccess = (data) => ({
    type: DEBUG_SCREEN_API_SUCCESS,
    payload: data ,
});

export const debug_screenApiFail = (actionType, error) => ({
    type: DEBUG_SCREEN_API_FAIL,
    payload: { actionType, error },
});


export const getdebug_screenData = (queryParam,callback) =>({
    type: GET_DEBUG_SCREEN_DATA,
    payload: queryParam,
    callback
})

export const setLoading = (payload) =>({
    type: SET_LOADING,
    payload
    
})
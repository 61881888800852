import {
    SUMMARY_DATA_API_SUCCESS,
    SUMMARY_DATA_API_FAIL,
    GET_SUMMARY_CHART_DATA,
    GET_SUMMARY_DATA,
    SET_LOADING
} from "./actionTypes";

export const summaryApiSuccess = (actionType, data) => ({
    type: SUMMARY_DATA_API_SUCCESS,
    payload: { actionType, data } ,
});

export const summaryApiFail = (actionType, error) => ({
    type: SUMMARY_DATA_API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getSummaryChartsData = (queryParam,callback) => ({
    type: GET_SUMMARY_CHART_DATA,
    payload: queryParam,
    callback
});

export const getSummaryData = (queryParam) =>({
    type: GET_SUMMARY_DATA,
    payload: queryParam
})

export const setIsLoading = (payload) =>({
    type: SET_LOADING,
    payload
    
})
import {
    GET_TABLE_DATA,
    DATA_TABLE_API_SUCCESS,
    DATA_TABLE_API_FAIL,SET_SELECTED_FILES,
    GET_CHANGING_DATA,
    SET_CHANGING_DATA_RESPONSE
} from './actionTypes';



export const tableDataApiSuccess = (payload) => (
    {
        type: DATA_TABLE_API_SUCCESS,
        payload
    }
)

export const tableDataApiFail = (actionType, error) => (
    {
        type: DATA_TABLE_API_FAIL,
        payload: { actionType, error }
    }
)
export const getTableData = (queryParam, callback) => (
    {
        type: GET_TABLE_DATA,
        payload: queryParam,
        callback
    }
)
export const setSelectedFiles = (payload) => (
    {
        type: SET_SELECTED_FILES,
        payload
    }
)
export const getChangingData = (queryParam,callback) => (
    {
        type: GET_CHANGING_DATA,
        payload: queryParam,
        callback
    }
)

export const setChangingDataResponse = (payload) => (
    {
        type: SET_CHANGING_DATA_RESPONSE,
        payload
    }
)


import { detail_dashboard_api_2} from "helpers/backend_helper";
import {   DETAIL_DASHBOARD_API_FAIL, DETAIL_DASHBOARD_API_SUCCESS, GET_DETAIL_DASHBOARD_DATA } from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { detailApiFail, detailApiSuccess } from "./actions";
import toastr from "toastr";





function* getDetailDashboardApiData({payload : queryParam, callback}) {
    try {
        var response;
        console.log("saga"+{...queryParam})
        response = yield call(detail_dashboard_api_2,queryParam);
        console.log("response"+response);
        yield put(detailApiSuccess(response));
        //localStorage.setItem("detail_dashboard",response?.OK)
        callback && callback(response)
    } catch (error) {
        yield put(detailApiFail(DETAIL_DASHBOARD_API_FAIL, error));
    }
}


function* detailDashboardSaga() {
    yield takeEvery(GET_DETAIL_DASHBOARD_DATA, getDetailDashboardApiData);
}


export default detailDashboardSaga;

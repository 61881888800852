import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Row, Tooltip } from 'reactstrap';
import { getTableData } from 'store/actions';

function RecordingStatus({ details }) {
    const user = useSelector(state => state.Login.user);
    const sid = user.sessionID;
    const permission = (useSelector(state => state.Login?.userData?.data?.roleType?.[0]?.permission));
    const [role, setRole] = useState([]);
    const [liveStatus, setliveStatus] = useState(false);
    const [chartsData, setChartData] = useState([]);
    const dates = useSelector(state => state.Active_Store?.dates);
    let startDate = dates?.startDate;
    let endDate = dates?.endDate;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const liveCameras = useSelector(state => state.Live_Camera_Data?.data);
    const macAddress = useSelector(state => state.Active_Store?.macAddress);
    const activeCamera = useSelector(state => state.Active_Store?.activeCamera);
    const dispatch = useDispatch();
    useEffect(() => {
        if (permission !== null) {
            setRole(permission.split(",").map(Number));
        }
    }, [])
    const getDataWithDates = (startDate, endDate, rGraph) => {
        let dateArray = [];
        let data = [];
        let currentDate = moment(startDate);
        const formattedEndDate = moment(endDate);

        while (currentDate.isSameOrBefore(formattedEndDate, 'day')) {
            dateArray.push(currentDate.format('YYYY-MM-DD'));
            let df = rGraph.find(val => currentDate.isSame(val.date));
            if (df) {
                data.push(parseInt(df.dfiles));
            } else {
                data.push(0);
            }

            currentDate = currentDate.add(1, 'days');
        }

        return { data, dateArray };
    }

    const options = {
        chart: { sparkline: { enabled: !0 } },
        stroke: { curve: "smooth", width: 2 },
        colors: ["#3B71CA"],
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 10,
                inverseColors: false,
                opacityFrom: 0.7,
                opacityTo: 0.05,
                stops: [25, 100, 100, 100],
            },
        },
        tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
            shared: true,
            followCursor: false,
            intersect: false,
            inverseOrder: false,
            custom: undefined,
            hideEmptySeries: true,
            fillSeriesColor: false,
            theme: false,
            style: {
                fontSize: '12px',
                fontFamily: undefined
            },
            onDatasetHover: {
                highlightDataSeries: false,
            },
            x: {
                show: true,
                format: 'dd MMM',
                formatter: undefined,
            },
            y: {
                formatter: undefined,
                title: {
                    formatter: (seriesName) => seriesName,
                },
            },
            z: {
                formatter: undefined,
                title: 'Size: '
            },
            marker: {
                show: false,
            },
            items: {
                display: "flex",
            },
            fixed: {
                enabled: false,
                position: 'topRight',
                offsetX: 0,
                offsetY: 0,
            },
        }
    }
    const getChartDataForAll = (details, options) => {
        let data = [];
        let cameraList = details?.RouteDetail?.Cam_Details;
        // console.log("Recording ", details);
        for (let index = 0; index < cameraList?.length; index++) {
            let chartOptions = { ...options };
            let chart = {};
            const element = cameraList[index];
            let name = element.Cam;
            let series = [];
            let xaxisData = [];
            let dataWithDate = {
                dateArray: [],
                data: []
            };

            if (element.RGraph.length > 0) {
                dataWithDate = getDataWithDates(startDate, endDate, element.RGraph);
            }


            chartOptions['xaxis'] = {
                type: "datetime",
                categories: dataWithDate.dateArray,
                labels: {
                    show: true,
                    format: "dd MMM"
                }

            }

            chart['title'] = element.Cam;
            chart['colors'] = "primary";
            chart['value'] = element.Cam;
            chart['series'] = [{ name: "files", data: dataWithDate.data }];
            chart['options'] = chartOptions;


            data.push(chart);
        }
        return data;
    }

    // const chartsData = getChartDataForAll(details, options);

    console.log("Chart Data Recording", chartsData);


    useEffect(() => {
        let data = getChartDataForAll(details, options);
        setChartData(data);
    }, [details])

    useEffect(() => {
        if (liveCameras) {
            let liveRoute = liveCameras?.data?.filter(item => item.live.bus === activeCamera);
            if (liveRoute?.length > 0) {
                setliveStatus(true);
            } else {
                setliveStatus(false);
            }
        } else {
            setliveStatus(false);
        }
    }, [liveCameras, details, activeCamera])
    const handleForceRecording = () => {
        if (macAddress) {
            let queryParam = {
                "sid": sid,
                "date": startDate,
                "camid": macAddress,
                "rid": 7,
                "enddate": endDate,
                "payload": "",
            }
            dispatch(getTableData(queryParam))
        }
        else {
            console.log("No mac Address");
        }
    }

    return (
        <React.Fragment>
            <Card className='mb-2' style={{ height: "97%" }}>
                <CardBody className='pt-2 pb-0'>
                    <CardTitle>
                        <Row >
                            <Col sm={12} md={8}>
                                <h3>Recording Camera</h3>
                            </Col>
                            <Col sm={12} md={4} className="d-flex justify-content-center justify-content-sm-start" id="Force_Recording">
                                <button className={`btn btn ${liveStatus && role.includes(36) ? 'btn-primary' : 'btn-secondary'} btn-sm p-2`} disabled={!liveStatus || !role.includes(36)} onClick={handleForceRecording}>Force Recording</button>
                                {!liveStatus && <Tooltip
                                    isOpen={tooltipOpen}
                                    placement="bottom"
                                    target="Force_Recording"
                                    toggle={() => { setTooltipOpen(!tooltipOpen) }}>
                                    No Live Camera
                                </Tooltip>
                                }
                            </Col>
                        </Row>
                    </CardTitle>
                    <br />
                    {(chartsData || [])?.map((report, key) => (
                        <Col key={key} className='pb-4 mb-4 text-center'>
                            <Row >
                                <Col xs={12} xl={4} className='p-0 mt-4 text-center'>
                                    <div className=' mt-3' >
                                        <h5>{report.value}</h5>
                                    </div>
                                </Col>
                                <Col xs={12} xl={8}>
                                    <div>
                                        <div>
                                            <ReactApexChart options={report.options} series={report.series} type="area" height={70} className="apex-charts" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default RecordingStatus
import {
    GET_LIVE_CAMERA_DATA,
    LIVE_CAMERA_DATA_API_SUCCESS,
    LIVE_CAMERA_DATA_API_FAIL,
    
} from "./actionTypes";

export const liveCameraDataApiSuccess = (data) => ({
    type: LIVE_CAMERA_DATA_API_SUCCESS,
    payload: data ,
});

export const liveCameraDataApiFail = (actionType, error) => ({
    type: LIVE_CAMERA_DATA_API_FAIL,
    payload: { actionType, error },
});


export const getLiveCameraData = (queryParam,callback) =>({
    type: GET_LIVE_CAMERA_DATA,
    payload: queryParam,
    callback
})

import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Label, Modal, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "../../assets/scss/theme.scss";
import { useSelector } from 'react-redux';
import Camera_Details_Status from './Camera_Details_Status';
import { useDispatch } from 'react-redux';
import { getCameraActive, setMacAddress } from 'store/actions';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { getLiveCameraData } from 'store/actions';
import Cam_Detail_Form from './Add_Modify_cam_detail';
function CamerasDetails({ details, activeCamera }) {

  const dispatch = useDispatch();

  const cameraList = details?.RouteDetail?.Cam_Details;
  
  const liveCameraData = useSelector(state => state.Live_Camera_Data?.data);
  // console.log("live data",liveCameraData);
  const user = useSelector(state => state.Login.user);
  const sid = user.sessionID;
  const activeCamera1 = useSelector(state => state.Active_Store?.activeCamera);
  const macAddress = useSelector(state => state.Active_Store?.macAddress);
  const ref1 = useRef(null);
  const [snr,setSNR] =  useState("") ;
  const [tx_rate,setTx_rate] =  useState("") ;
  const [s_strength,setS_strength] =  useState("") ;
  const [files_rem,setFileRem] = useState("");
  const [duration,setDuration] = useState("");
  const files_remaining  = Math.round( files_rem * 100) / 100;
  useEffect(() => {

    if (ref1.current) {
      clearInterval(ref1.current)
    }
    ref1.current = setInterval(async () => {
      console.log("Interval Calls Live DL Status", ref1.current);
      if (macAddress) {
        let queryParams = {
          sid: sid,
          mac: macAddress,
        }
        dispatch(getLiveCameraData(queryParams));
      }
    }, 30000);
    return () => {
      if (ref1.current) {
        clearInterval(ref1.current)
      }
    }
  }, [macAddress,activeCamera1]);


  const [customActiveTab, setcustomActiveTab] = useState(activeCamera);

  // console.log("Tab Value ",customActiveTab);

  const getActiceCameraDetails = (activeCameraTab) => {
    if (cameraList) {
      const cameraDetails = cameraList.find((item) => item.Cam === activeCameraTab);
      console.log(cameraDetails);
      if (cameraDetails) {
        return cameraDetails;
      }
    }
  }


  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const setColor = (status) => {
    if (status == 'A')
      return "#FFBF00"
    if (status == 'Y')
      return "#FFFF00"
    if (status == 'R')
      return "#FF0000"
    if (status == 'G')
      return "#00FF00"
  }

  useEffect(() => {
    console.log("Active Camera TAAB", customActiveTab);
    const camera = getActiceCameraDetails(customActiveTab);
    let activeCamera = camera?.Cam;
    let macAddress = camera?.mac;
    let liveStatus = camera?.live === 1 ? true : false;
    dispatch(getCameraActive({ activeCamera, macAddress, liveStatus }));
    let queryParams = {
      sid: sid,
      mac: macAddress,
    }
    dispatch(getLiveCameraData(queryParams)); 
    setLiveData(liveCameraData,customActiveTab);   
    
  }, [customActiveTab]);

  useEffect(() => {
    setcustomActiveTab(activeCamera1);
  }, [activeCamera1])

  useEffect(() => {
    setLiveData(liveCameraData,customActiveTab);
  }, [liveCameraData]);


  const setLiveData = (storeLiveData,activeTab) =>{

    console.log("DL _ Status", storeLiveData);
    console.log("DL _ Status", activeTab);
    if (storeLiveData) {
        let liveData = storeLiveData?.data?.filter(item => item.live.bus === activeTab);
          console.log("filter item",liveData);
        if(liveData?.length > 0 ){
            setSNR(liveData[0]?.live?.SNR);
            setTx_rate(liveData[0]?.live?.tx_rate);
            setS_strength(liveData[0]?.live?.S_strength)
            setFileRem(liveData[0]?.live?.files_rem);
            setDuration(liveData[0]?.live?.["time remaining minutes"] + "");
        }else{
          setSNR("");
          setTx_rate("");
          setS_strength("");
          setFileRem("");
          setDuration("");
          console.log("Values",snr,duration,files_rem);    
        }

    } else {
      setSNR("");
      setTx_rate("");
      setS_strength("");
      setFileRem("");
      setDuration("");
      console.log("SNR ",snr);
    }

    cameraList?.map((val) =>{
      let liveData = storeLiveData?.data?.filter(item => item.live.bus === val.Cam);
      if(liveData?.length > 0){
        val.flicker = true;
      }else{
        val.flicker = false;
      }
    })

  }
  

  const annotations = {
    xaxis: [
      {
        x: snr,
        borderColor: '#FF4500',
        label: {
          text: `${snr}`,
          style: {
            color: '#FF4500',
          },
        },

      },
    ],
  };

  

  const series = [
    {
      data: [100],
    },
  ]
  const options = {
    chart: {
      type: 'bar',
      height: 20,
      toolbar: {
        show: false, // Hides the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        endingShape: 'flat',
      },
    },
    colors: [' #ffd700'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.25,
        gradientToColors: ['red', 'yellow', 'green'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 10,
            color: 'red'
          },
          {
            offset: 50,
            color: 'yellow'
          },
          {
            offset: 80,
            color: 'green'
          }
        ]
      },
    },
    xaxis: {
      categories: [''],
      labels: {
        show: false, // Hides X-axis labels
      },
      axisBorder: {
        show: false, // Hides X-axis border
      },
      axisTicks: {
        show: false, // Hides X-axis ticks
      },
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },


  };

  options.annotations = annotations;
const [isshowDetailOpen, setIsShowDetailOpen] = useState(false);
const [caminfo,setCamInfo] = useState("");
const showDetail =()=> {
  setIsShowDetailOpen(!isshowDetailOpen);
  if(cameraList)
  {
    cameraList.map((item) => {
      if(activeCamera1 === item.Cam)
      {
        setCamInfo(item);
        console.log(caminfo);
      }
    })
  }

}
  return (
    <React.Fragment>
      <Card className='cam_and_route'>
        <CardBody className='pb-1 pt-1'>
          <h3><center>Cameras</center></h3>
          <Nav tabs className="nav-tabs-custom nav-justified">
            {
              cameraList && cameraList.map(item => (
                <NavItem key={item.Cam}>
                  <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === item.Cam,'p-0': true})} onClick={() => { toggleCustom(item.Cam); }}>
                    <span className="d-sm-block"> <i className={`bx bxs-circle ${ item.flicker ? 'bx-burst' : ''} `} style={{ color: setColor(item.Status) }} /> {item.Cam}</span>
                  </NavLink>
                </NavItem>
              ))

            }
          </Nav>

          <TabContent
            activeTab={customActiveTab}
            className="p-2 pb-0 text-muted"
          >

            {
              cameraList && cameraList.map(item => (
                <TabPane tabId={item.Cam} key={item.Cam} >
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <Row>
                          <Col sm={3}>
                            <Camera_Details_Status data={item} />
                          </Col>
                          <Col sm={4} className='text-center'>
                            <Row>
                              <span>Last Seen Online</span>
                              <span><h6>{moment(item.AccessDate).format("YYYY-MM-DD")}</h6></span>
                            </Row>
                            <br />
                            <Row>
                              <span>Files Seen in Camera</span>
                              <span><h6>{moment(item.PlDate).format("YYYY-MM-DD")}</h6></span>
                            </Row>
                            <br />
                            <Row>
                              <span>Files Downloaded</span>
                              <span><h6>{moment(item.RecDate).format("YYYY-MM-DD")}</h6></span>
                            </Row>
                            <br />
                            <Row>
                              <Col>
                                <span><h6>{files_remaining ? files_remaining : "N/A"}</h6></span>
                                <span>Files Remaining</span>
                              </Col>
                              <Col>
                                <span><h6>{duration ? duration : "N/A"}</h6></span>
                                <span>Duration</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={5} className='font'>
                            <span><b>Parameters:</b></span>
                            <br />
                            <span>Name: {item.Cam}</span>
                            <br />
                            <span>IP Address:  {item.ip}</span>
                            <br />
                            <span>MACAddress: {item.mac}</span>
                            <br />
                            <span>RTSP Port: {item.rtspport}</span> 
                            <br/>
                            <span>ONVIF Port: {item.oport}</span>
                            <br />
                            <Row>
                              <label className='pb-0 mb-0'>SNR : {snr ? snr : "N/A"}</label>
                              <label className='pb-0 pt-0 mb-0'  >Tx Rate: {tx_rate ? tx_rate : "N/A"}</label>
                              <label  className='pb-0 pt-0 mb-0'>Signal Strenght : {s_strength ? s_strength : "N/A"}</label>
                              <ReactApexChart options={options} series={series} type="bar" height="80"  width="170"/>
                            </Row>
                          </Col>
                        </Row>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
              ))

            }
          </TabContent>
        </CardBody>
      </Card>
      <Modal isOpen={isshowDetailOpen} style={{maxWidth:"650px"}} toggle={()=> {setIsShowDetailOpen(!isshowDetailOpen)}}>
        <ModalHeader toggle={()=> {setIsShowDetailOpen(!isshowDetailOpen)}}>Cam Details</ModalHeader>
        <Cam_Detail_Form  camDetail ={caminfo}/>
      </Modal>
    </React.Fragment>
  )
}

export default CamerasDetails
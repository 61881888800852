import React from "react";

import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";

import ApexRadial from "./Bus_RecordingsApexRadial";

const Bus_Recording = ({ Bus_Recording }) => {
  const DownloadsToday = Bus_Recording?.BusRecordings?.DownloadsToday;
  const Bus_Live = Bus_Recording?.BusRecordings?.Bus_Live;
  const speed = Bus_Recording?.BusRecordings?.speed;
  const TotalPending = Bus_Recording?.BusRecordings?.TotalPending;
  const Green = Bus_Recording?.BusRecordings?.Green;
  const Yellow = Bus_Recording?.BusRecordings?.Yellow;
  const Amber = Bus_Recording?.BusRecordings?.Amber;
  const Red = Bus_Recording?.BusRecordings?.Red;
  const completion = Bus_Recording?.BusRecordings?.percentcompletion;
  return (
    <React.Fragment>
      {" "}
      <Card className="summary_card">
        <CardHeader><h4>Bus Recordings</h4></CardHeader>
        <CardBody>
          <Row >
            <Row>
                <div>
                  <ApexRadial dataColors='["--bs-primary"]' percentcompletion={completion !== undefined ? completion : 0} />
                </div>
            </Row>
            <Row className="pt-2" >
              <Col>
                <p>
                  <h5>{DownloadsToday} Files</h5>
                  <span className="text-muted">Downloaded Today</span> 
                </p>
              </Col>
              <Col>
                <p>
                <h5 className="text-center">{Bus_Live}</h5>
                  <span className="text-muted">Bus Camera Live</span> 
                </p>
              </Col>
              <Col>
                <p>
                <h5 className="text-center">{speed} mbps</h5>
                  <span className="text-muted">Cumulative Speed</span> 
                </p>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col><p className="text-muted">{Green ? Green : "0"}</p><i className={`bx bxs-circle`} style={{ color: "#00FF00" }} /></Col>
              <Col><p className="text-muted">{Yellow ? Yellow : "0"}</p><i className={`bx bxs-circle`} style={{ color: "#FFFF00" }} /></Col>
              <Col><p className="text-muted">{Red ? Red : "0"}</p><i className={`bx bxs-circle`} style={{ color: "#FFBF00" }} /></Col>
              <Col><p className="text-muted">{Amber ? Amber : "0"}</p><i className={`bx bxs-circle`} style={{ color: "#FF0000" }} /></Col>
              <Col><p className="text-muted">TotalPending</p><center>{TotalPending}</center></Col>
            </Row>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Bus_Recording;

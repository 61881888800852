import {
    DEBUG_SCREEN_API_SUCCESS,
    DEBUG_SCREEN_API_FAIL,
    SET_LOADING,
} from "./actionTypes";

const INIT_STATE = {
    data: {},
    Loading: true
};

const Debug_Screen_Detail = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DEBUG_SCREEN_API_SUCCESS:
            return {
                ...state,
                data: action.payload.OK,
                Loading: false
            };

        case DEBUG_SCREEN_API_FAIL:
            return {
                ...state,
                data: action.payload.error
            };
        case SET_LOADING:
            return {
                ...state,
                Loading: action.payload
            }
        default:
            return state;
    }
}

export default Debug_Screen_Detail;
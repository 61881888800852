import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import ecommerceSaga from "./e-commerce/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import jobsSaga from "./jobs/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import dashboardCryptoSaga from "./dashboard-crypto/saga";
import dashboardBlogSaga from "./dashboard-blog/saga";
import dashboardJobSaga from "./dashboard-jobs/saga";
import summaryDashboardSaga from "./summary-dashboard/saga";
import detailDashboardSaga from "./detail-dashboard/saga";
import routeListSaga from "./route-list/saga";
import dataTableSaga from "./table-data/saga";
import liveCameraDataSaga from "./fetch-live-data/saga";
import subGroupsSaga from "./subgroup-list/saga";
import snapShotSaga from "./snapshot/saga";
import addCamSaga from "./add-cam/saga"
import addFormSaga from "./add-form/saga"
import debug_ScreenSaga from "./debug-screen/saga"
import searchMacSaga from "./search-mac/saga"
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(jobsSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(dashboardCryptoSaga),
    fork(dashboardBlogSaga),
    fork(dashboardJobSaga),
    fork(summaryDashboardSaga),
    fork(detailDashboardSaga),
    fork(routeListSaga),
    fork(dataTableSaga),
    fork(liveCameraDataSaga),
    fork(subGroupsSaga),
    fork(snapShotSaga),
    fork(addCamSaga),
    fork(addFormSaga),
    fork(debug_ScreenSaga),
    fork(searchMacSaga)
  ]);
}
